// Communication with parent window if any
// =========

'use strict';

var $ = require('../../../bower_components/jquery/dist/jquery');
var core = require('../core');

var post = {
  postMessageToParent: function(obj) {
    if(window.top == window.self) {
      return false;
    } else {  
      window.parent.postMessage(obj, '*');
    }
  },

  postCurrentHeightToParent: function() {
    var obj = {
      type: 'HEIGHT_CHANGE',
    };
    if($('.search-app').css('display') === 'none') {
      var whereResultsHeight = (function() {
        if($('#where-results').css('display') === 'none') {
          return 0;
        } else {
          return $('#where-results').height();  
        }
      })();
      obj.height = ($('header').height() + whereResultsHeight) + 'px';
    } else {
      obj.height = '100%';
    }
    return post.postMessageToParent(obj);
  },
  height: function(height) {
    post.postMessageToParent({height: height, type: 'HEIGHT_CHANGE'});
  },
  enableHeightChange: function() {
    post.postMessageToParent({type: "ENABLE_HEIGHT_CHANGE"});
  },
  takeOver: function() {
    post.postMessageToParent({type: 'TAKE_OVER'});
  },
  signIn: function() {
    post.postMessageToParent({type: 'SIGN_IN', redirectUrl: window.location.href});
  },
  signOut: function() {
    post.postMessageToParent({type: 'SIGN_OUT', redirectUrl: window.location.href});
  },
  userSettings: function(username) {
    post.postMessageToParent({type: 'USER_SETTINGS', username: username});
  },
  userReviews: function(username) {
    post.postMessageToParent({type: 'USER_REVIEWS', username: username});
  },
  userFavourites: function(username) {
    post.postMessageToParent({type: 'USER_FAVOURITES', username: username});
  },
  latestActivity: function(username) {
    post.postMessageToParent({type: 'LATEST_ACTIVITY', username: username});
  },
  logInAs:function(username, fakeUser) {
    post.postMessageToParent({type: 'LOG_IN_AS',username: username, fakeUser: fakeUser});
  },
  sendWhere: function(where) {
    post.postMessageToParent({type: 'WHERE_UPDATE', where: where});
  },
  history: {
    push: function(state, url) {
      post.postMessageToParent({type: 'HISTORY_PUSH', url: url, state: state});
    }
  },
  forCompanies: function() {
    post.postMessageToParent({type: 'FOR_COMPANIES'});
  },
  addBusiness: function() {
    post.postMessageToParent({type: 'ADD_BUSINESS'});
  },
  editBusinesses: function() {
    post.postMessageToParent({type: 'EDIT_BUSINESSES'});
  },
  payedSearch: function() {
    var strip = window.location.href.replace(/widget=true/, '');
    strip = strip.replace(/hideSearchBoxes=true/, '');
    var params;
    if(strip.match(/\?/)) {
      params = "&paying=1";
    } else {
      params = "paying=1";
    }

    post.postMessageToParent({type: 'PAYED_SEARCH', url: strip + params});
  }
};

module.exports = post;
