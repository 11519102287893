'use strict';

var state = {
  setInitial: function(obj) {
    if(obj.IBB) {
      state.params.insideBoundingBox = obj.IBB;
      delete state.params.aroundRadius;
      delete state.params.aroundPrecision;
      state.method = 'map';
    }
    if(obj.ALL) state.params.aroundLatLng = obj.ALL;
    if(obj.ZL) state.map.zoom = Number(obj.ZL);
  },
  update: function(obj) {
    for(var key in obj) {
      state[key] = obj[key];
    }
  },
  changed: false,
  what: '',
  method: 'where',
  map: {
    zoom: 8
  },
  params: {
    getRankingInfo: 1,
    hitsPerPage: 25,
    highlightPreTag: '<b class="marked">',
    highlightPostTag: "</b>",
    numericFilters: '',
    facetFilters: [],
    aroundRadius: 25000,
    aroundPrecision: 25000,
    page: 0,
    ruleContexts: 'categories-enabled'
  }
};

module.exports = state;
