'use strict';

// This module calculates a URL based on a 'state' and 'filters' Object
var url = {
  protocol: '',
  domain: '',
  baseRoute: '',
  what: '',
  whereId: '',
  whereSlug: '',
  setInitial: function(location){
    // * Sets the initial values on page load based
    // on a window.location object from the browser
    // * argument type: Object
    url.protocol = location.protocol;
    url.domain = location.host;
    var path = location.pathname.split('/');
    url.baseRoute = 'search';//path[1];
    url.what = path[2];
    url.whereId = path[3];
    url.whereSlug = path[4];
    var params = url.stringToObject(location.search, '&', '=');
    if(location.search.match(/IBB/)){
      return 'map';
    }else{
      return 'where';
    }
  },
  paramsSchema: {
    // Table to use for converting abbreviated
    // properties to full name properties and vice versa
    'numericFilters': 'NF',
    'aroundLatLng': 'ALL',
    'insideBoundingBox': 'IBB',
    'aggregateRating': 'AR',
    'ReviewCount': 'RC',
    'StarAverage': 'SA',
    '_geoloc.lat': 'lat',
    '_geoloc.lng': 'lng',
    'zoomLevel': 'ZL',
    'categories': 'CAT',
    'businessGroups': 'BG',
    'serviceBoundaries': 'SB'
  },
  collapse: function(str) {
    // * Abbreviates strings based on paramsSchema
    // * argument type: String
    var shorts = url.paramsSchema;
    for(var key in shorts) {
      str = str.replace(new RegExp(key, 'g'), shorts[key]);
    }
    return str;
  },
  expand: function(str) {
    // * Unabbreviates strings based on paramsSchema
    // * argument type: String
    var lengths = url.paramsSchema;
    for(var key in lengths) {
      str = str.replace(new RegExp(lengths[key], 'g'), key);
    }
    return str;
  },
  expandObject: function(obj) {
    // * Unabbreviates keys on an object based on paramsSchema
    // * argument type: Object
    var out = {};
    for(var key in obj) {
      out[url.expand(key)] = url.expand(obj[key]);
    }
    return out;
  },
  entries: function(state, filters, location) {
    // * Prepares and validates object values
    // * argument types: Object, Object, Object
    return {
      businessGroup: filters.BG ? filters.BG.split(',')[0] : '',
      category: filters.CAT ? filters.CAT.split(',')[0] : '',
      searchTerm: state.query || 'none'
    };
  },
  locationSlug: function(location) {
    // * Returns a slug for an Algolia 'geo_locations' object
    // argument type: Object
    var city = location.location_name;
    var province = location.location_province;
    var country = location.location_country;
    var locationSlug = '';
    locationSlug += city ? city : '';
    locationSlug += province ? ", " + province : '';
    locationSlug += country ? ", " + country : '';
    return locationSlug;
  },
  makeTitle: function(state, filters, location) {
    // * Returns a string to use in the TITLE tag in html for the Search-app
    // * argument types: Object, Object, Object
    var entries = url.entries(state, filters, location);
    var term = entries.businessGroup || entries.category || entries.searchTerm;
    if(term === "none") term = "All Results";
    term = term[0].toUpperCase() + term.substr(1);

    // where search
    var locationSlug = "", locationIn = "";
    if(location.objectID) {
      locationSlug = url.locationSlug(location);
      locationIn = (entries.businessGroup ? " locations" : '') + " in ";
      if(location.location_name === "Planet Earth") {
        locationIn = " locations ";
        locationSlug = "";
      }
    } else {
      locationSlug = "Custom Location";
      locationIn = entries.businessGroup ? " locations in " : " in ";
    }
    return term + locationIn + locationSlug;

  },
  makeMetaDescription: function(state, filters, location, hits) {
    // Returns a string to use for META tag in html for the Search-app
    // * argument types: Object, Object, Object, Array
    var start = "Find and compare reviews for ";
    var entries = url.entries(state, filters, location);
    var term = entries.businessGroup || entries.category || entries.searchTerm;
    if(term === "none") term = "All Results";
    var locationSlug = url.locationSlug(location);
    var businesses = "";
    var list = hits.splice(0,5);
    var maxIndex = Math.min(4, list.length - 1);
    list.forEach(function(hit, i) {
      businesses += hit.bName;
      if(entries.businessGroup) businesses += " " +hit.bAddr1;
      if(i < maxIndex) businesses += ", ";
    });
    var locationIn = (entries.businessGroup ? " locations" : '') + " in ";
    if(location.location_name === "Planet Earth") {
      locationIn = " locations";
      locationSlug = "";
    }
    var meta = start + term + locationIn + locationSlug +
               " | " + businesses;
    return meta;
  },
  make: function(state, filters){
    // * Returns a valid URL for the Search-app. It can be copied and shared
    // with others and return the same results.
    // * argument types: Object, Object
    url.what = state.what;
    var params = "", categories = Object.keys(filters.savedCategories),
        businessGroups = Object.keys(filters.savedBusinessGroups);

    if(filters.minReviewRating > filters.default.minReviewRating){
      if(params) params += "&";
      params = 'StarAverage=' + filters.minReviewRating;
    }
    if(filters.minReviews > filters.default.minReviews){
      if(params) params += "&";
      params += 'ReviewCount=' + filters.minReviews;
    }

    if(categories.length) {
      if(params) params += "&";
      params += "categories=";
      categories.forEach(function(cat, i){
        params += cat.replace('&', encodeURIComponent('&'));
        if(i < categories.length-1) params += ",";
      });
    }

    if(businessGroups.length) {
      if(params) params += "&";
      params += "businessGroups=";
      businessGroups.forEach(function(cat, i){
        params += cat.replace('&', encodeURIComponent('&'));
        if(i < businessGroups.length-1) params += ",";
      });
    }

    if(state.method === 'map' && state.params.insideBoundingBox) {
      if(params) params += "&";
      params +=
      "aroundLatLng=" + state.params.aroundLatLng +
      "&insideBoundingBox=" + state.params.insideBoundingBox;
    }

    params =  url.collapse(params);
    var str = "/" + url.baseRoute +
              "/" + url.what +
              "/" + url.whereId +
              "/" + url.whereSlug +
              "/";
    if(params){
      str += "?" + params;
    }
    return str;
  },
  // Helper functions
  arrayToObject: function(arr, sep){
    var obj = {};
    var pair;
    for(var i = 0, l = arr.length; i < l; i += 1){
      pair = arr[i].split(sep);
      obj[pair[0].trim()] = decodeURIComponent(pair[1]);
    }
    return obj;
  },
  stringToObject: function(str, sep1, sep2) {
    var pairs = str.split(sep1);
    var obj = url.arrayToObject(pairs, sep2);
    return obj;
  },
  objectToString: function(obj, join1, join2, prepend) {
    var prefix = prepend || '';
    var arr = [];
    for(var key in obj){
      arr.push(prefix + key + join1 + encodeURIComponent(obj[key]));
    }
    return arr.join(join2);
  },
  arrayToString: function(arr, match, replace) {
    if(!arr && !arr.forEach) return;
    var reg = new RegExp(match, 'g');
    return arr.toString().replace(reg, replace);
  },
  compareStrings: function(str1, str2) {
    var same = true;
    var len = Math.min(str1.length, str2.length);
    var matches = [];
    var differences = [];
    for(var i = 0; i < len; i += 1){
      if(str1[i] === str2[i]){
        matches.push({
          index: i,
          words: str1.substr(i -2, 5) +"::"+str2.substr(i -2, 5),
          letters: str1[i] + ":" + str2[i]
        });
      }else{
        same = false;
        differences.push({
          index: i,
          words: str1.substr(i -2, 5) +"::"+str2.substr(i -2, 5),
          letters: str1[i] + ":" + str2[i]
        });
      }
    }
    return {same: same, differences: differences, matches: matches};
  },
};

module.exports = url;
