'use strict';

var post = require('./post');

var history = {
  states:{},
  id: 0,
  newState: false,
  replace: function(state, name, url) {
    if(window.top !== window) post.history.replace();
    var historyState = history.searchStateToHistoryState(state);
    history.newState = true;
    history.states[historyState.historyId] = historyState;
    window.history.replaceState(historyState, history.id.toString(), url);
  },
  push: function(state, name, url, locationObject) {
    if(history.newState) {
      history.id += 1;
      var historyState = history.searchStateToHistoryState(state, locationObject);
      history.states[historyState.historyId] = historyState;
      history.newState = false;
      window.history.pushState(historyState, history.id.toString(), url);
    }
  },
  searchStateToHistoryState: function(state, locationObject) {
    return {
      method: state.method === 'map' ? 'history' : 'where',
      what: state.what,
      params: state.params,
      changed: state.changed,
      drained: state.drained,
      map: state.map,
      id: history.id,
      locationObject: locationObject || ''
    };
  }
};

module.exports = history;
