'use strict';

var helpers = {

  getIcon: function(icons, name) {
    if(!icons || !icons[name]) return '';
    return icons[name];
  },

  breakLines: function(text) {
    var newText = text.replace(/(\r\n|\n|\r)/gm, '<br/>');
    return newText;
  },

  times: function(n, block) {
    var accum = '';
    for(var i = 0; i < n; ++i)
        accum += block.fn(i);
    return accum;
  },

  trimString: function(str, charCount) {
    if(!str) str = '';
    return str.substring(0, charCount);
  },

  needTrim: function(str, neededCount, options) {
    if(str && str.length >= neededCount) return options.fn(this);
    return options.inverse(this);
  },

  needWordTrim: function(str, neededCount, options) {
    if(str.split(' ').length > neededCount) return options.fn(this);
    return options.inverse(this);
  },

  removeTrailingCommas: function(str) {
    if(str[0] === ',') str = str.substr(1);
    if(str[str.length - 1] === ',') str = str.substr(0, str.length - 2);
    return str;
  },

  encodeHtml: function(text) {
    return encodeURIComponent(text);
  },

  decodeHtml: function(text) {
    return decodeURIComponent(text);
  },

  ifCond: function(v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
  },

  exposeEnvironment: function() {
    var env = process.env.EXECUTION_ENV,
        out = 'Environment is ';
    if(!env) {
      return out + 'undefined, most likely running in dev.';
    }
    if(env === 'development') {
      return out + 'development, good to go.';
    }
    if(env === 'staging') {
      return out + 'staging.';
    }
    if(env === 'production') {
      return out + 'production, woohoo!';
    }
    return out + 'this is happening!!1';
  },

  productionOnly: function(options) {
    if(process.env.EXECUTION_ENV === 'production') {
      return options.fn(this);
    }
  },

  belongForm: function(string) {
    var lastTwo = string.substr(string.length - 2);
    if(lastTwo === "'s") {
      return string;
    }
    if(lastTwo === 's') {
      return string + "'";
    }
    return string + "'s";
  },

  replaceString: function(string, find, replace) {
  	return string.replace(find, replace);
  },

  formatAddress: function(address) {
    var parts = address.split(',');
    var newAddress = '';
    for(var i = 0, l = parts.length; i < l; i += 1) {
      if(parts[i] != 'null') {
        newAddress += parts[i];
        if(i !== l - 1) {
          newAddress += ', ';
        }
      }
    }
    return newAddress.trim();
  },

  shouldBePlural: function(stringSingular, withNumber) {
    if(withNumber === 1) {
      return stringSingular;
    } else {
      return stringSingular + 's';
    }
  },

  json: function(context) {
    if(!context) {
      return JSON.stringify({});
    }
    return JSON.stringify(context).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029');
  },

  /** returns random RGB like this rgb(50, 100, 200) */
  randomRGB: function() {
    return 'rgb(' + Math.round(Math.random() * 255) +
           ', '   + Math.round(Math.random() * 255) +
           ', '   + Math.round(Math.random() * 255) +
           ')';
  },

  arrayToObject: function(arr, sep){
    var obj = {};
    var pair;
    for(var i = 0, l = arr.length; i < l; i += 1){
      pair = arr[i].split(sep);
      obj[pair[0]] = decodeURIComponent(pair[1]);
    }
    return obj;
  },

  stringToObject: function(str, sep1, sep2) {
    var pairs = str.split(sep1);
    var obj = this.arrayToObject(pairs, sep2);
    return obj;
  },

  objectToString: function(obj, join1, join2) {
    var arr = [];
    for(var key in obj){
      arr.push(key + join1 + encodeURIComponent(obj[key]));
    }
    return arr.join(join2);
  },

  getTruncatedHtml: function(maxLength, html, isUtf8) {
    isUtf8 = isUtf8 || true;
    var ord = function(str) {
      str = str + "";
      var code = str.charCodeAt(0);
      if (0xD800 <= code && code <= 0xDBFF) {
        var hi = code;
        if (str.length === 1) {
          return code;
        }
        var low = str.charCodeAt(1);
        return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000;
      }
      if (0xDC00 <= code && code <= 0xDFFF) {
        return code;
      }
      return code;
    };

    var result = "";

    var printedLength = 0;
    var position = 0;
    var tags = [];

    var re = isUtf8 ? "</?([a-z]+)[^>]*>|&#?[a-zA-Z0-9]+;|[\x80-\xFF][\x80-\xBF]*" : "</?([a-z]+)[^>]*>|&#?[a-zA-Z0-9]+;";
    var match;
    while (printedLength < maxLength && (match = html.substr(position).match(re))) {
      var tag = match[0];
      var tagPosition = match.index + position;

      var str = html.substr(position, tagPosition - position);
      if (printedLength + str.length > maxLength) {
        result += str.substr(0, maxLength - printedLength);
        printedLength = maxLength;
        break;
      }

      result += str;
      printedLength += str.length;
      if (printedLength >= maxLength) {
        break;
      }

      if (tag[0] == "&" || ord(tag) >= 0x80) {
        result += tag;
        printedLength++;
      } else {
        var tagName = match[1];
        if (tag[1] == "/") {
          var openingTag = tags.pop();
          result += tag;
        } else if (tag[tag.length - 2] == "/") {
          result += tag;
        } else {
          result += tag;
          tags.push(tagName);
        }
      }

      position = tagPosition + tag.length;
    }

    if (printedLength < maxLength && position < html.length) {
      result += html.substr(position, maxLength - printedLength);
    }

    while (tags.length) {
      result += "</" + tags.pop() + ">";
    }
    return result;
  },
};


module.exports = helpers;
