// Core Javascript module with convinience methods,
// this module should be highly portable and not dependant
// on any other modules.
//
// Also, this module should only include laconic pure functions


var core = {};

core.isMobile = function() {
  return window.innerWidth < 805;
  return /Mobi/.test(navigator.userAgent);
};

core.isTouchScreen = function() {
  return !!window.navigator.userAgent.match(/Mobi|Tablet|iPad/i);
}
module.exports = core;
