// Search module implements anything related to the search page on n49

'use strict';

var core = require('./core.js');
global.jQuery = global.$ = require('../../bower_components/jquery/dist/jquery');
var Handlebars = require('../../bower_components/handlebars/handlebars');
var customSelect = require('../../bower_components/jquery.customSelect/jquery.customSelect.min');
var algoliasearch = require('../../bower_components/algoliasearch/dist/algoliasearch.min');
var noUiSlider = require('../../bower_components/nouislider/distribute/nouislider.min');
var handlebarsHelpers = require('../../handlebars-helpers');
// window.MarkerClusterer
require('../../bower_components/js-marker-clusterer/src/markerclusterer');
var state = require('./search/state');
var filters = require('./search/filters');
var suggestion = require('./search/suggestion');
// window.MarkerWithLabel
require('./lib/markerwithlabel');
var url = require('../../server/modules/urlQuery');
var store = require('./search/store');
var helpers = require('../../server/modules/general-helpers');
require('../../bower_components/history.js/scripts/bundled/html4+html5/jquery.history');
var user = require('./search/user');
var post = require('./search/post');
var introJs = require('../../bower_components/intro.js/minified/intro.min');
var track = require('./search/track');

var search = {

  attachEvents: function() {
    $('.search-filter-rating-option')       .on('click', search.filterRatingOptionClicked);
    $('.sidebar-header')                    .on('click', search.toggleFilters);
    $('#selected-categories-container')     .on('click', '.filter-checkbox-selected', search.toggleCategory);
    $('#filter-categories')                 .on('click', '.filter-checkbox-wrapper', search.toggleCategory);
    $('#selected-business-group-container') .on('click', '.filter-checkbox-selected', search.toggleBusinessGroup);
    $('#filter-business-groups')            .on('click', '.filter-checkbox-wrapper', search.toggleBusinessGroup);
    $(window)                               .on('scroll', search.update.scroll);
    $('#load-more-button')                  .on('click', search.update.nextPage);
    $('.search-results')                    .on('click', '.read-more', search.update.seeMoreDescription);
    $('.search-results')                    .on('click', '.read-less', search.update.seeLessDescription);
    $('.search-results')                    .on('click', '.read-more-review', search.update.seeMoreReview);
    $('.search-results')                    .on('click', '.read-less-review', search.update.seeLessReview);
    $('.search-results')                    .on('mouseover', '.search-hit', search.highlightHit);
    $('#show-all-categories')               .on('click', search.update.toggleCategories);
    $('.search-results')                    .on('click', '.see-more', search.update.seeMoreCategories);
    $('.search-results')                    .on('click', '.see-less', search.update.seeLessCategories);
    $('#show-all-business-groups')          .on('click', search.update.toggleBusinessGroups);
    $('#search-filter-flush-filters-button').on('click', search.flushAllFilters);
    // filters overview
    $('.search-results')                    .on('click', '.search-hit-category-link', search.chooseCategoryFromHit);
    $('.filters-selected-overview-wrap')    .on('click', '.filter-overview-stars', search.filterRatingOptionRemoveClicked);
    $('.filters-selected-overview-wrap')    .on('click', '.filter-overview-minreviews', search.reviewRatingFlushedFromOverviewFilter);
    $('.filters-selected-overview-wrap')    .on('click', '.filter-overview-reset', search.flushAllFilters);
    $('.filters-selected-overview-wrap')    .on('click', '.filter-overview-saved-group', search.toggleBusinessGroup);
    $('.filters-selected-overview-wrap')    .on('click', '.filter-overview-saved-category', search.toggleCategory);
    $('.search-results')                    .on('click', '.suggestion-search-more', search.planetEarthSearchFallback);
    $('.search-results')                    .on('click', '.search-hit', search.trackClickOnHitClick);
    $('.search-results')                    .on('click', '.search-hit-contentlist-item', search.searchHitContentListItemClicked);

    $('.partners-popup-close-button')       .on('click', search.closePartnersPopup);
    $('.partners-popup-screen-lock')        .on('click', search.closePartnersPopup);

  },

  historyIframeControlled: false,

  attachHeaderEvents: function() {
    $(document.body)                        .on('click', function(e) {
      if(search.loggedinWidgetShown) return;
      if(e.target.id == 'search-mobile-input-where' || e.target.id == 'search-header-input-where') return;
      if($(e.target).hasClass('header-buttons-collapsed-toggle')) return;
      if($(e.target).parents().hasClass('header-buttons-collapsed-toggle')) return;
      search.update.hideWhere();
    });
    $('#search-header-input-where')         .on('click', search.update.showHome);
    $('#search-header-input-where')         .on('focus', search.update.showHome);
    $('#search-header-input-where')         .on('focus', search.hideMenuFooter);
    $('#search-header-input-where')         .on('blur', search.showMenuFooter);
    $('#search-header-input-where')         .on('blur', search.update.hideWhere);
    $('#search-header-input-what')          .on('keyup', search.delayedWhatSearch);
    $('#search-header-input-what')          .on('focus', search.update.focusWhat);
    $('#search-header-input-what')          .on('focus', search.hideMenuFooter);
    $('#search-header-input-what')          .on('blur', search.showMenuFooter);
    $('#search-header-input-where')         .on('keyup', search.update.where);
    $('#where-results')                     .on('mousedown', '.suggestion', function(e){e.preventDefault();});
    $('#where-results')                     .on('click', '.suggestion', search.update.where);
    $('#search-what-erase-button')          .on('click', search.update.clearWhat);
    $('.header-logo')                       .on('click', search.redirectIndex);
    $('#header-sign-in-button')             .on('click', user.signin);
    $('#header-for-companies')              .on('click', user.forCompanies);
    $('#header-add-business-button')        .on('click', user.addBusiness);
    $('#header-user-menu-button')           .on('click', search.toggleLoggedInWidget.bind(this, null));
    $('#logoutHeader')                      .on('click', user.signout);
    $('.user-logged-in-item.user-settings')                     .on('click', user.settings);
    $('.user-logged-in-item.user-reviews')                      .on('click', user.reviews);
    $('.user-logged-in-item.user-favourites')                   .on('click', user.favourites);
    $('.user-logged-in-item.user-log-in-as')                    .on('click', user.logInAs.toggleMenu);
    $('.user-log-in-as-button')                                 .on('click', user.logInAs.login);
    $('.user-logged-in-item.user-latest-activity')              .on('click', user.latestActivity);
    $('.user-logged-in-item.user-edit-businesses')              .on('click', user.editBusinesses);
    $('.user-logged-in-item.header-add-business-button')        .on('click', user.addBusiness);
    $('.user-logged-in-item.user-payed-search')                 .on('click', user.payedSearch);
    $(window)                               .on('statechange', search.handleStateChange);
    $(window)                               .on('message onmessage', search.handleIncomingMessage);
    $(window)                               .on('resize', search.shouldCreateHamburgerMenu);
    $('.header-buttons-collapsed-toggle')   .on('click', search.showNeededWidget);
    $('.details-container')                 .on('click', '.search-hit-contentlist-item', search.searchHitContentListItemClicked);
    $('.partners-popup-close-button')       .on('click', search.closePartnersPopup);
    $('.partners-popup-screen-lock')        .on('click', search.closePartnersPopup);
  },

  attachDesktopEvents: function() {
    $('.search-results')               .on('mouseover', '.search-hit', search.highlightHit);
    $('.search-results')               .on('click', '.search-hit-wrapper', search.selectHit);
    $('.search-results')               .on('mouseout', search.mouseMovedOutOfHits);
    $('#map-zoom-in')                  .on('click', search.update.map.zoomIn);
    $('#map-zoom-out')                 .on('click', search.update.map.zoomOut);
    $('#move-off')                     .on('click', search.update.searchHere);
    $('#move-and-search input')        .on('click', search.update.searchOnMove);
    $('#search-map')[0].addEventListener('wheel', search.update.map.scroll, true);
    $('.search-wrapper-toggle')        .on('click', search.layoutStateToggleByArrow);
    $(window)                          .on('resize', search.windowResized);
    $('.header-logo')                  .on('click', search.redirectIndex);
  },
  mobileEventsAttached: false,
  attachMobileEvents: function() {
    if(search.mobileEventsAttached) return;
    search.mobileEventsAttached = true;
    $('#mobile-login-screen-login-button')  .on('click', user.signin);
    $('#mobile-add-business-button')        .on('click', user.addBusiness);
    $('#mobile-add-review-button')          .on('click', user.addReview);
    $('#search-mobile-input-what')          .on('keyup', search.update.mobile.what);
    $('.header-mobile-menu-icon')           .on('click', search.decideToggleTarget);
    $('.search-filters-mobile')             .on('click', search.toggleFiltersMobile);
    $('.search-top-mobile-trigger')         .on('click', search.decideToggleTarget);
    $('.search-mobile-inputs-close')        .on('click', search.decideToggleTarget);
    $('#search-mobile-inputs-button')       .on('click', search.update.mobile.search);
    $('#search-mobile-input-where')         .on('keyup', search.update.where);
    $('#search-mobile-input-where')         .on('click', search.update.showHome);
    $('#search-mobile-input-where')         .on('focus', search.update.showHome);
    document.getElementById('search-mobile-input-where')         .addEventListener('touchstart', search.hideMenuFooter, {passive: true});
    document.getElementById('search-mobile-input-where')         .addEventListener('touchleave', search.showMenuFooter, {passive: true});
    document.getElementById('search-mobile-input-what')          .addEventListener('touchstart', search.hideMenuFooter, {passive: true});
    document.getElementById('search-mobile-input-what')          .addEventListener('touchleave', search.showMenuFooter, {passive: true});
    $('.header-mobile-logo')                .on('click', search.redirectIndex);
    $('#search-mobile-where-suggestions')   .on('click', '.suggestion', search.update.where);
  },

  showMenuFooter: function() {
    if(!core.isTouchScreen()) return;
    $('.stick-to-bottom.text-center.made-in-toronto').show();
  },

  hideMenuFooter: function() {
    if(!core.isTouchScreen()) return;
    $('.stick-to-bottom.text-center.made-in-toronto').hide();
  },

  searchTimeout: (function(){
    var timer = 0;
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })(),

  delayedWhatSearch: function() {
    search.searchTimeout(search.update.what, 400);
  },

  redirectIndex: function() {
    window.top.location = '/';
  },

  setPageTitle: function() {
    var filterStr = filters.make.facet() + ',' + filters.make.numeric();
    var collapsed = url.collapse(filterStr);
    var collapsedObj = url.stringToObject(collapsed, ',', ':');
    document.title = url.makeTitle({query: state.what}, collapsedObj, search.results.where.current);
  },

  firstHistoryFlag: false,
  handleStateChange: function(e) {
    var _state = History.getState();
    state.update(_state.data);
    // Switches between showing a details page
    // and a search page
    // HOWEVER
    // if going from a details page to a search page
    // via browser history navigation AND said page
    // has not been loaded, we need to reload the page
    if(_state.hash && _state.hash.match(/^\/biz/)) {
      if(!$('#details-outer-container').length) {
        window.location.reload();
      }
      search.hideMainContent();
    } else {
      if(!$('.search-app').length) {
        window.location.reload();
      }
      search.showMainContent();
    }
    if(!search.firstHistoryFlag) {
      search.firstHistoryFlag = true;
    } else
    if(!_state.noSearch){
      search.update.page(0);
      filters.flushOverviewCategories();
      filters.flushOverviewGroups();
      search.update.state();
      search.setPageTitle();
    }
    return false;
  },
  getStackTrace: function() {
    var obj = {};
    Error.captureStackTrace(obj, search.getStackTrace);
    return obj.stack;
  },
  handleIncomingMessage: function(e) {
    e = e.originalEvent || e;
    search.historyIframeControlled = true;
    if(e.data.type === 'cookie') {
      var match = e.data.cookie.match('userMenu');
      var parentCookie = url.stringToObject(e.data.cookie, ';', '=');
      if(match) {
        user.data = JSON.parse(parentCookie.userMenu);
        return user.setStatus();
      }
    }
    var actions = {
      STATE_SYNC: function() {
        // in case user clicked forward in parent window
        // we need to reassure that child sends takeover message again
        post.takeOver();
        search.showMainContent();
        search.historyIframeControlled = true;
        History.replaceState(e.data.state, '', e.data.url);
      },
      TAKE_ME_OVER: function() {
        search.appendMainContent();
        search.showMainContent();
        state.what = e.data.stateWhat;
        search.results.where.current = e.data.stateWhere;
        url.whereId = e.data.stateWhere.objectID;
        url.whereSlug = e.data.stateWhere.location_name;
        suggestion.select(e.data.stateWhere);
        $('.search-fields-wrapper').show();
        search.firstHistoryFlag = true;
        search.updateHistoryState();
        $(search.getWhatInputSelector()).focus();
        post.takeOver();
      },
      TAKE_OVER_PAUSE: function() {
        search.hideMainContent();
        post.enableHeightChange();
        try { state.setInitial(); } catch(e) {}
        state.what = '';
        $(search.getWhatInputSelector()).val('').blur();
        search.update.state();
      },
      HIDE_SEARCH_INPUT: function() {
        $('.search-fields-wrapper').hide();
      },
      REQUEST_WHERE: function() {
        var whereCheck = setInterval(function() {
          if(search.results.where.current) {
            post.sendWhere(search.results.where.current);
            clearInterval(whereCheck);
            whereCheck = null;
            return;
          }
        }, 100);
      },
      UPDATE_WHERE: function() {
        var where = JSON.parse(e.data.where);
        search.results.where.current = where;
      },
      SHOW_SEARCH_INPUT: function() {
        $('.search-fields-wrapper').show();
      },
      DOMAIN_NOT_N49: function() {
        var domainParts = e.data.domain.split('.');
        var domain = domainParts.slice(domainParts.length - 2, domainParts.length - 1);
        $('.header-logo').css('margin-top', '15px');
        $('.header-logo').html(domain + ' <img src="/searchapp-static/images/dotcaicon.svg" class="dotca-portal-svg" />');
      },
      sayWhat: function() {

      }
    };
    return (actions[e.data.type] || actions.sayWhat)();
  },

  adjustLayoutToggleButton: function(future) {
    if($('.search-wrapper-toggle-wrap').is(':animated')) {
      return false;
    }
    future = future ? future : {};
    var sidebarWidth = future.sidebarWidth ? future.sidebarWidth : $('.sidebar').width();
    var searchWrapperWidth = future.searchWrapperWidth ? future.searchWrapperWidth : $('.search-wrapper').width();
    var leftOffset = sidebarWidth + searchWrapperWidth;
    $('.search-wrapper-toggle-wrap').animate({
      left: leftOffset
    }, 100);
  },

  windowResized: function() {
    search.adjustLayoutToggleButton();
    search.setHeights();
    search.resizeSuggestionWrapper();

  },

  shouldCreateHamburgerMenu: function() {
    if($(window).width() < 806) {
      search.attachMobileEvents();
    }
    if($(window).width() < 1130) {
      $('#user-add-a-business').show();
      $('.header-buttons-collapsed-toggle').css('display', 'inline-block');
      $('.header-buttons').hide();
    } else {
      $('#user-add-a-business').hide();
      $('.header-buttons-collapsed-toggle').hide();
      $('.header-buttons').css('display', 'inline-block');
    }
  },

  showNeededWidget: function() {
    if(user.isLoggedIn()) {
      return search.toggleLoggedInWidget('.header-buttons-collapsed-toggle');
    }
    var selector = core.isMobile() ? '.mobile-login-screen' : '.not-logged-in-widget';
    var offset = $('.header-buttons-collapsed-toggle').offset();
    if($(selector).css('display') === 'none') {
      $(selector).fadeIn(100, function() {
        // post.height($(selector).height());
        if(selector == '.mobile-login-screen') {
          post.height('full height');
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'fixed';
          $('.header-buttons-collapsed-toggle.mobile.pull-right i')
          .removeClass('icon-hamburgermenu').addClass('icon-close-btn');
        } else {
          post.height($(selector).height());
        }

      });
    } else {
      $(selector).fadeOut(100, function() {
        post.enableHeightChange();
        post.height(0);
        if(selector == '.mobile-login-screen') {
          document.body.style.overflow = 'initial';
          document.body.style.position = 'relative';
          $('.header-buttons-collapsed-toggle.mobile.pull-right i')
          .removeClass('icon-close-btn').addClass('icon-hamburgermenu');
        }
      });
    }
  },
  resizeSuggestionWrapper: function() {
    if(core.isMobile()) return;
    $('#where-results').css({
      left: $('.search-field-where').offset().left + 'px'
    });
    $('#where-results').width($('.search-field-where').width());
  },

  validateLocation: function(hit) {
    if(hit.location_viewport) {
      search.update.map.setBoundsOnStateFromWhere(hit);
      delete state.params.aroundRadius;
      delete state.params.aroundPrecision;
    } else {
      state.params.aroundLatLng = hit._geoloc.lat + ',' + hit._geoloc.lng;
      state.params.aroundRadius = 25000;
      state.params.aroundPrecision = 25000;
      delete state.params.insideBoundingBox;
    }
  },

  updateHistoryState: function(noSearch) {
    search.setPageTitle();
    if(search.historyIframeControlled) search.firstHistoryFlag = true;
    var neededFunction = search.historyIframeControlled ? History.replaceState : History.pushState;
    var newUrl = url.make(state, filters);
    var currentURL = decodeURIComponent(window.location.pathname + window.location.search);
    if(currentURL !== newUrl) {
      search.firstHistoryFlag = true;
      neededFunction(state, document.title, url.make(state, filters));
      post.history.push(
        JSON.parse(
          JSON.stringify(
            Object.assign({},state, {noSearch: noSearch})
          )
        ),
        newUrl
      );
    }
  },

  reviewRatingFlushedFromOverviewFilter: function() {
    $('#search-filter-reviews-slider')[0].noUiSlider.set(0);
    $('.filter-overview-minreviews').remove();
    search.update.minReviews();
  },

  update: {
    // call historyUpdateState() after every event that
    // should make a new
    state: function(shouldIgnoreHistoryPush) {
      search.closeAllInfoWindows();
      state.drained = false;
      state.params.facetFilters = filters.make.facet();
      state.params.ruleContexts = filters.make.ruleContexts();
      search.update.whatElements();
      search.update.whereElements();
      // We do not want to do any geo search when location_type is less than 4 (country, country region and province)
      if(state.method !== 'map' && Number(search.results.where.current.location_type) < 4) {
        delete state.params.insideBoundingBox;
        delete state.params.aroundRadius;
        // Markers rely on a valid aroundLatLng so we need to save it for the addMarkers function
        if(state.params.aroundLatLng) search.addMarkers.fakeAroundLatLng = state.params.aroundLatLng;
        delete state.params.aroundLatLng;
      }
      if(state.params.page === 0) window.scrollTo(0,0);
      search.algolia.what();
    },
    whatElements: function() {
      var valueForInputWhat = (state.what === 'none') ? '' : state.what;
      $(search.getWhatInputSelector()).val(valueForInputWhat);
      $('.search-criteria-overview-what').text(state.what);
      if(state.what === '') state.what = 'none';
      if($('.search-criteria-overview-what').text() === 'none') {
        $('.search-criteria-overview-what').text('');
        $('#search-criteria-overview-in').text('');
      } else {
        $('#search-criteria-overview-in').text('in');
      }
    },
    whereElements: function() {
      if(search.method === 'map') {
        $('#search-header-input-where').val('Custom Location');
        $('.search-criteria-overview-where').text('Custom Location');
      }
    },
    mobile: {
      search: function() {
        if(core.isMobile() && window.location.pathname.match(/^\/biz/)) {
          return window.location.href = "/search/" + $('#search-mobile-input-what').val() + "/" + search.results.where.current.objectID + "/" + search.results.where.current.location_name;
        }
        $('#details-outer-container').hide();
        $('#details-desktop-footer').hide();
        $('.mobile-login-screen').hide();
        $(document.body).css('overflow', 'auto')
        search.update.what();
        search.updateHistoryState();
      },
      what: function(e) {
        state.what = $('#search-mobile-input-what').val();
        if(state.what === '') state.what = 'none';
      }
    },
    reviewRating: function(e) {
      var original = filters.minReviewRating;
      filters.selectReviewRating.call(this, e);
      if(original !== filters.minReviewRating) {
        state.params.numericFilters = filters.make.numeric();
        search.updateHistoryState();
      }
    },
    minReviews: function(e) {
      var original = filters.minReviews;
      filters.minReviews = parseInt($('#search-filter-reviews-min-value').text(), 10);

      if(original !== filters.minReviews) {
        search.updateMinReviewsOverviewFilter(filters.minReviews);
        state.params.numericFilters = filters.make.numeric();
        search.updateHistoryState();
      }
    },
    toggleCategories: function() {
      if($('#filter-categories').data('show-all')) {
        filters.collapseChildren('#filter-categories');
        $('#show-all-categories').text('+ See more categories');
        $('#filter-categories').data('show-all', false);
      }else {
        filters.expandChildren('#filter-categories');
        $('#show-all-categories').text('- See fewer categories');
        $('#filter-categories').data('show-all', true);
      }
    },
    toggleBusinessGroups: function() {
      if($('#filter-business-groups').data('show-all')) {
        filters.collapseChildren('#filter-business-groups');
        $('#show-all-business-groups').text('+ See more business groups');
        $('#filter-business-groups').data('show-all', false);
      }else {
        filters.expandChildren('#filter-business-groups');
        $('#show-all-business-groups').text('- See fewer business groups');
        $('#filter-business-groups').data('show-all', true);
      }
    },
    clearWhat: function(){
      state.what = "none";
      $(search.getWhatInputSelector()).val('');
      search.updateHistoryState();
      $('#search-header-input-what').focus();
    },
    focusWhat: function(e) {
      if(!search.mainLoaded) search.appendMainContent();
    },
    what: function(e) {
      console.log('comes here at least', search.mainLoaded, search);
      if(!search.mainLoaded) search.appendMainContent();
      filters.ruleContexts = 'categories-enabled';
      filters.clearQueryRules();
      search.showMainContent();
      // $('.search-app').css('display', 'block');
      post.takeOver();
      var original = state.what;
      state.what = $(search.getWhatInputSelector()).val();
      if(search.historyIframeControlled) {
        search.algolia.what();
      }
      if(state.what !== original) {
        if(state.what === '') state.what = 'none';
        search.updateHistoryState();
      }
    },
    where: function(e){
      if(e && e.originalEvent && (e.originalEvent.keyCode === 9 || e.originalEvent.keyCode === 16)) return;
      var up = 38;
      var down = 40;
      var enter = 13;
      var click = e.type === 'click';
      var del = 46;
      var backspace = 8;
      if(e.keyCode === del || e.keyCode === backspace) {
        search.update.showWhere();
      }
      if(e.keyCode === up) {
        suggestion.traverse(-1);
      }else
      if(e.keyCode === down) {
        suggestion.traverse(1);
      } else if(e.keyCode === enter || click) {
        var id = $(this).data('index') !== undefined ? $(this).data('index') : suggestion.currentHover - 1;
        var hit = search.results.where.lastSearch.hits[Number(id)];
        if(!hit) return;
        search.results.where.current = hit;
        suggestion.select(hit);
        search.validateLocation(hit);
        url.whereId = hit.objectID;
        url.whereSlug = hit.location_name;
        store.setWhere(hit.objectID);
        search.update.hideWhere();
        $('.search-criteria-overview-where').text(hit.location_name);
        if(search.mainLoaded && search.mainContentShown) {
          if(!core.isMobile()) {
            search.map.maxZoom = 18;
          }
          filters.setServiceBoundaries(hit);
          state.drained = false;
          state.method = search.method = 'where';
          search.updateHistoryState();
        }
        if(search.historyIframeControlled) {
          search.update.state();
        }
      } else {
        search.algolia.where($(this).val());
      }
    },
    showHome: function(e){
      e.preventDefault();
      // search.resizeSuggestionWrapper();
      $(this).val('');
      var selector = core.isMobile() ? '#search-mobile-where-suggestions' : '#where-results';
      $(selector).empty();
      search.results.where.lastSearch = search.results.where.homeResults;
      var hits = core.isMobile() ? search.results.where.lastSearch.hits : search.results.where.lastSearch.hits;
      $(selector).append(search.render.suggestions(hits));
      search.update.showWhere();
      return false;
    },
    showWhere: function() {
      var selector = core.isMobile() ? '#search-mobile-where-suggestions' : '#where-results';
      $(selector).show();
      $(selector).css('opacity', 1);
      if(!$('.mobile-login-screen').is(':visible')) {
        post.height($(selector).height());
      }
      $('.search-mobile-input-where-wrapper').addClass('container-shadow');
    },
    hideWhere: function() {
      var selector = core.isMobile() ? '#search-mobile-where-suggestions' : '#where-results';
      $(selector).fadeOut(100, function() {
        if($('.not-logged-in-widget').css('display') === 'none' &&
           $('.user-logged-in-wrapper').css('display') === 'none' &&
           $('.mobile-login-screen').css('display') === 'none'){
          post.height(0);
        }
      });
      // if nothing was selected select the last/current result
      if(!$(selector).val()) suggestion.select(search.results.where.current);
      $('.search-mobile-input-where-wrapper').removeClass('container-shadow');
    },
    page: function(page) {
      state.params.page = page;
      if(state.params.page === 0) {
        $('.search-results')[0].scrollTop = 0;
      }
    },
    seeMoreCategories: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-categories');
      var seeLess = search.hit.category.seeLess(data.id);
      data.hit.categoriesHL = data.hit.categoriesHL.map(function(category) {
        return '<a href="#" class="search-hit-category-link">' + category + '</a>';
      });
      var str = url.arrayToString(data.hit.categoriesHL, ',', ', ');
      $(data.parent).html(str + seeLess);
      e.preventDefault();
    },
    seeLessCategories: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-categories');
      var seeMore = search.hit.category.seeMore(data.id);
      data.hit.shortCategories = data.hit.shortCategories.map(function(category) {
        return '<a href="#" class="search-hit-category-link">' + category + '</a>';
      });
      var str = url.arrayToString(data.hit.shortCategories, ',', ', ');
      $(data.parent).html(str + seeMore);
      e.preventDefault();
    },
    seeMoreDescription: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-description');
      var seeMore = search.hit.description.seeMore(data.id);
      $(data.parent).html(data.hit.descriptionHL + seeMore);
      e.preventDefault();
    },
    seeLessDescription: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-description');
      var seeLess = search.hit.description.seeLess(data.id);
      $(data.parent).html(data.hit.shortDescription + seeLess);
      e.preventDefault();
    },
    seeMoreReview: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-latest-review-text');
      var seeMore = search.hit.review.seeMore(data.id);
      $(data.parent).html('“' + data.hit.reviewDescriptionHL + '” ' + seeMore);
      e.preventDefault();
    },
    seeLessReview: function(e) {
      var data = search.hit.getParent(this, '', 'search-hit-latest-review-text');
      var seeLess = search.hit.review.seeLess(data.id);
      $(data.parent).html('“' + data.hit.shortReviewDescription + '” ' + seeLess);
      e.preventDefault();
    },
    scroll: function(e) {
      var documentHeight = $(document).height();
      var scrollTop = $(window).scrollTop();
      var windowHeight = $(window).height();
      if(core.isMobile()) {
        scrollTop = window.pageYOffset;
        search.didScroll = true;
        setInterval(function() {
          if (search.didScroll) {
            search.shouldHideMobileHeader();
            search.didScroll = false;
          }
        }, 250);

      }

      if(state.drained) return false;
      var delta = documentHeight - scrollTop - windowHeight;
      if(delta < 56) {
        if(search.loadMoreLock) return false;
        search.update.nextPage();
      }
    },
    searchOnMove: function(e){
      search.searchOnMove = !search.searchOnMove;
    },
    searchHere: function(e) {
      if(!search.searchOnMove) {
        $('#move-on').removeClass('hidden');
        $('#move-off').addClass('hidden');
        search.update.map.search();
      }
    },
    map: {
      zoomIn: function(e, level) {
        state.method = search.method = 'map';
        state.map.zoom = level || search.map.getZoom() + 1;
        search.zoomMap();
        if(search.searchOnMove){
          search.update.map.setBoundsOnState();
          search.updateHistoryState();
        }else{
          search.update.map.showRedoButton();
        }
      },
      zoomOut: function(e, level) {
        state.method = search.method = 'map';
        state.map.zoom = level || search.map.getZoom() - 1;
        search.zoomMap();
        if(search.searchOnMove){
          search.update.map.setBoundsOnState();
          search.updateHistoryState();
        }else{
          search.update.map.showRedoButton();
        }
      },
      scroll: function(e) {
        search.map.maxZoom = 21;
        if(search.searchOnMove){
          state.method = search.method = 'map';
          if(!search.isScrollingMap){
            search.isScrollingMap = true;
            setTimeout(function(){
              search.update.map.setBoundsOnState();
              search.updateHistoryState();
              search.isScrollingMap = false;
            },40);
          }
        }else{
          search.update.map.showRedoButton();
        }
      },
      search: function(e) {
        state.method = search.method = 'map';
        filters.serviceBoundaries = {};
        search.update.map.setBoundsOnState();
        search.updateHistoryState();
      },
      setBoundsOnStateFromWhere: function(location) {
        var center = location._geoloc;
        var northEast = location.location_viewport.northeast;
        var southWest = location.location_viewport.southwest;
        var topLeftLat = northEast.lat;
        var topLeftLng = southWest.lng;
        var bottomRightLat = southWest.lat;
        var bottomRightLng = northEast.lng;
        var latc = center.lat;
        var lngc = center.lng;
        state.map.center = {lat: latc, lng: lngc};
        state.map.bounds = {bottomRight: {lat: bottomRightLat, lng: bottomRightLng}, topLeft: {lat: topLeftLat, lng: topLeftLng}};
        if(search.map) state.map.zoom = search.map.getZoom();
        state.params.aroundLatLng = '' + latc + ',' + lngc;
        state.params.insideBoundingBox = [bottomRightLat,bottomRightLng, topLeftLat, topLeftLng].toString();
      },
      setBoundsOnState: function(location) {
        location = location || {};
        // longitude is x-wise
        // latitude is y-wise
        // north pole is positive 90 degrees latitude
        // south pole is negative 90 degrees latitude
        var bounds = search.map.getBounds();
        delete state.params.aroundRadius;
        var center = bounds.getCenter();
        var northEast = bounds.getNorthEast();
        var southWest = bounds.getSouthWest();
        var topLeftLat = northEast.lat();
        var topLeftLng = southWest.lng();
        var bottomRightLat = southWest.lat();
        var bottomRightLng = northEast.lng();
        var latc = center.lat();
        var lngc = center.lng();
        state.map.center = {lat: latc, lng: lngc};
        state.map.bounds = {bottomRight: {lat: bottomRightLat, lng: bottomRightLng}, topLeft: {lat: topLeftLat, lng: topLeftLng}};
        state.map.zoom = search.map.getZoom();
        state.params.aroundLatLng = '' + latc + ',' + lngc;
        state.params.insideBoundingBox = [bottomRightLat,bottomRightLng, topLeftLat, topLeftLng].toString();
      },
      dragEnd: function(mode){
        state.method = search.method = 'map';
        search.map.maxZoom = 21;
        if(search.searchOnMove){
          search.update.map.search();
        }else{
          search.update.map.showRedoButton();
        }
      },
      showRedoButton: function(){
        $('#move-on').addClass('hidden');
        $('#move-off').removeClass('hidden');
      }
    },

    nextPage: function() {
      if(search.loadMoreLock) return false;
      search.loadMoreLock = true;
      search.update.page(state.params.page + 1);
      search.update.state();
    },
    hitsScrollPosition: function(e) {
      // if we scoll to the bottom, a new search will be made so we need
      // a safety margin for the max scroll
      var safetyMargin = 100;
      var searchCriteriaHeight = 69;
      var childIndex = this.hitIndex + 1;
      var headerHeight = $('header').height();//$('header.container-fluid')[0].clientHeight;
      $('.search-results').children().removeClass('search-hit-wrapper-selected');
      var element = $('.search-results .search-hit-wrapper:nth-child('+ childIndex +')');
      element.toggleClass('hovered').toggleClass('search-hit-wrapper-selected');
      var containerHeight = window.innerHeight;
      var scrollMax = $('.search-results').height() - containerHeight - safetyMargin;
      var scrollTo = element.offset().top - searchCriteriaHeight + 4;
      $('html,body').animate(
        {
          scrollTop: Math.min(scrollMax, scrollTo)
        },
        {
          duration: 'medium', easing: 'swing'
        }
      );
    }
  },

  scroll: {
    offsetY: 0,
    page: 0,
  },

  method: 'where',

  appendMainContent: function() {
    var initOptions = {
      algoliaRaw: search.results.what.lastSearch,
      initialQuery: $(search.getWhatInputSelector()).val(),
      initialParams: search.initialParams,
      initialHits: search.initialHits,
      initialLocation: search.results.where.current
    };
    var mainContent = $($('#template').html());
    mainContent.insertAfter('#template');
    search.mainLoaded = true;
    search.init(initOptions);
    setTimeout(function() {
      $('.search-top-mobile-trigger').on('click', search.decideToggleTarget);
      $('.search-filters-mobile').on('click', search.toggleFiltersMobile);
    },1);
  },

  hideMainContent: function() {
    $('.search-app').fadeOut(150);
    search.mainContentShown = false;
    search.showDetails();
  },

  showMainContent: function() {
    if($('.search-app').css('display') === 'none') {
      $('.search-app').fadeIn(150);
    }
    search.hideDetails();
    search.mainContentShown = true;
  },

  hideDetails: function() {
    $('#details-outer-container').hide();
  },

  showDetails: function() {
    $('#details-outer-container').show();
  },
  // Being called on load and also on each resize
  // ** Note, this function is depricated **
  setHeights: function() {
    return false;
    var height = window.innerHeight ? window.innerHeight : $(window).height();
    // changed dynamic to static header height, used to be $('header').height()
    var heightRespectHeader = height - 63;
    $('.search-app').height(heightRespectHeader);
    $('.search-wrapper').height(heightRespectHeader);
    $('.search-left').height(heightRespectHeader);
    $('.search-results').height(heightRespectHeader - $('.search-top').height());
  },

  // Unfolds the filter sidebar area
  filtersOpened: false,
  // Locks the button till previous FX finished
  filersToggleLock: false,
  toggleFilters: function() {
    if(search.filersToggleLock) return false;
    search.filersToggleLock = true;
    search.filtersOpened = !search.filtersOpened;
    search.adjustLayoutToggleButton({
      searchWrapperWidth: (search.filtersOpened ? 370 : 600),
      sidebarWidth: (!search.filtersOpened ? 44 : 250)
    });
    if(search.filtersOpened) {
      search.layoutStateSmall(false);
    } else {
      search.layoutStateLarge(false);
    }
    search.toggleLayoutStateFilters();
    $('.sidebar').animate({
      width: !search.filtersOpened ? 44 : 250
    }, 150, function() {
      $('.sidebar').toggleClass('sidebar-collapsed');
      search.filersToggleLock = false;
      search.triggerGoogleMapsResize();
    });
    $('.sidebar-filters-icon')
    .fadeToggle(0, function() {
      $('.sidebar-header-unfolded')
      .fadeToggle(0);
    });
    $('.sidebar-filters-align').toggleClass('sidebar-filters-align-active');
  },

  toggleFiltersMobile: function() {
    if(search.filtersOpened) {
      $('.search-mobile-state-opened').fadeOut(100);
      $('.search-filters-mobile-state-closed').fadeIn(100);
    } else {
      $('.search-mobile-state-opened').fadeIn(100);
      $('.search-filters-mobile-state-closed').fadeOut(100);
    }
    search.filtersOpened = !search.filtersOpened;
    $('.sidebar').fadeToggle(100);
  },

  historySetup: function() {
    if(window.top != window.self) {
      search.historyIframeControlled = true;
    } else {
      search.updateHistoryState();
    }
  },

  getWhereInputSelector: function() {
    return core.isMobile() ? '#search-mobile-input-where' : '#search-header-input-where';
  },

  getWhatInputSelector: function() {
    return core.isMobile() ? "#search-mobile-input-what" : '#search-header-input-what';
  },

  getTemplates: function() {
    suggestion.getTemplate();
    search.noResult.getTemplate();
    search.hit.getTemplate();
    search.hit.advertiser.getTemplate();
    search.suggestionSearch.getTemplates();
  },

  registerPartials: function() {
    var partials = {
      'search-hit-contentlist': 'templates/search-hit-contentlist.hbs',
      'search-hit-special-offer': 'templates/search-hit-special-offer.hbs'
    };
    for(var name in partials) {
      (function(_name, path) {
        $.get('/searchapp-static/' + path, function(content) {
          Handlebars.registerPartial(_name, content);
        });
      })(name, partials[name]);
    }
  },

  // if we need to customize the app behaviour
  // based on user's input
  secondaryHooks: function() {
    switch(state.what) {
      case 'disaster!!!':
        $('#search-map').addClass('shake');
      break;
    }
  },

  // Initializes the search feature
  init: function(options) {
    if(search.paying) {
      filters.paying = true;
    }
    search.icons = options.icons || {};
    search.searchOnMove = false;
    // set 'where' in cookie based on url
    search.validateLocation(options.initialLocation);
    store.setWhere(options.initialLocation.objectID);
    for(var helper in handlebarsHelpers) {
      Handlebars.registerHelper(helper, handlebarsHelpers[helper]);
    }
    search.originalUrl = window.location.href;
    search.method = url.setInitial(window.location);
    filters.setInitial(options.initialParams);
    state.setInitial(options.initialParams);
    state.what = options.initialQuery;
    if(options.initialQuery === 'none') {
      state.what = 'none';
      var placeHolder = "Plumber, Starbucks, Self Storage";
      $(search.getWhatInputSelector).val('');
    }
    search.initialQuery = options.initialQuery;
    search.initialHits = options.initialHits;
    search.initialParams = options.initialParams;
    search.initialLocation = options.initialLocation;
    filters.setServiceBoundaries(search.initialLocation);
    // the server will prefer location from cookie so
    // we need to update the url object.
    url.whereId = options.initialLocation.objectID || url.whereId;
    url.whereSlug = options.initialLocation.location_name || url.whereSlug;
    state.params.numericFilters = filters.make.numeric();
    state.params.facetFilters = filters.make.facet();
    search.attachEvents();
    search.getGeo(function(hit) {
      if(!search.results.where.current) {
        $(search.getWhereInputSelector()).val(search.initialLocation.location_name || hit.location_name);
      }
      search.initFilters();
      search.getTemplates();
      search.registerPartials();
      // initialLocation based on url has priority
      if(!search.historyIframeControlled) {
        search.results.where.current = search.initialLocation || search.results.where.current || hit;
      }
      suggestion.select(search.results.where.current);
      if(!state.params.aroundLatLng) state.params.aroundLatLng = search.geo.objectToString(search.results.where.current._geoloc);
      search.homeLocation = hit;
      if(options.initialFacets || search.initOptions.initialFacets) {
        filters.getTemplates(options.initialFacets || search.initOptions.initialFacets);
      }
      if(!core.isMobile()) {
        search.initMap(search.initialLocation._geoloc || hit._geoloc);
        search.attachDesktopEvents();
        search.getMapTooltipTemplate(search.synchronizeInitialHits);
        search.layoutStateLarge();
        // call this function just once for initial layout
        search.windowResized();
      } else {
        search.synchronizeInitialHits();
        search.layoutStateMobile();
        search.attachMobileEvents();
      }
      search.update.whatElements();
      search.update.whereElements();
      // search.historySetup();
      if(search.initialHits.length < 1) {
        state.drained = true;
        setTimeout(function() {
          search.suggestionSearch.search();
        }, 1000);
      }
      // when you come from n49 mobile site, you can search on IP
      // this should override any other settings in the URL and we here
      // do a new search to reflect that
      if(options.mobile && options.mobile.forceIP) {
        search.results.where.current = hit;
        suggestion.select(search.results.where.current);
        $('.search-criteria-overview-where').text(hit.location_name);
        state.params.aroundLatLng = '' + hit._geoloc.lat + ',' + hit._geoloc.lng;
        state.params.aroundRadius = 25000;
        state.params.aroundPrecision = 25000;
        delete state.params.insideBoundingBox;
        search.update.state();
      }
    });

  },

  startTutorial: function() {
    // tutorial is disabled
    return false;
    var intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro:
          "<div id='turorial-intro-image-container'>" +
            "<img src='/searchapp-static/images/n49-logo-new-stuff.png'/>" +
          "</div>" +
          "<div id='tutorial-intro-container'>" +
            "<p id='tutorial-intro-title'>Welcome to the new N49 search!</p>" +
            "We’ve been working hard " +
            "to make it easier for you to find and connect with local business." +
          "</div>"
        },
        {
          element: document.querySelector('.search-field-what'),
          intro: "<div class='tutorial-title'>Search</div><div class='tutorial-body'>" +
          "The cornerstone of the search system is the search box, " +
          "you can search for a specific business like “Starbucks”, or a category like “Plumbers”" +
          "</div>"
        },
        {
          element: document.querySelector('.search-field-where'),
          intro: "<div class='tutorial-title'>Location</div><div class='tutorial-body'>" +
          "Every search requires a location." +
          "This can be as small as a city or as big as a country. " +
          "We've found it works best with a city like “Toronto”. " +
          "If your search isn't returning any results try searching in a bigger area.</div>"
        },
        {
          element: document.querySelector('.search-results'),
          intro: "<div class='tutorial-title'>List results</div><div class='tutorial-body'>This is the good old fashioned list of results, "+
          "not much new here. " +
          "Clicking on the name of a business will take you to their details page " +
          "where you can find additional information and read reviews about the business. </div>",
          position: 'right'
        },
        {
          element: document.querySelector('.search-right-map'),
          intro: "<div class='tutorial-title'>Map results</div><div class='tutorial-body'>" +
          "<p>Search results are displayed on a handy map " +
          "to show you where the business is located.</p>" +
          "<p>Businesses that come to you or who have chosen to hide their address " +
          "(plumbers, locksmiths, towing companies, etc) won't show up on the map "+
          "but will be in the list on the left.</p>" +
          "<p>Pro Tip: You can further refine your search results by zooming " +
          "in and out or moving the map area around and then clicking " +
          "the 'Redo Search Here' button.</p></div>",
          position: 'left'
        },
        {
          element: document.querySelector('.sidebar'),
          intro: "<div class='tutorial-title'>Filters</div><div class='tutorial-body'>Here's where you can really start finding those hidden gems in your neighbourhood!." +
          "<p>We have filters that can help you further refine your search. " +
          "You can filter businesses by number of reviews, minimum rating, " +
          "or by category. We also have business groups which are collections of " +
          "businesses with multiple locations.</p></div>",
          position: 'right'
        },
        {
          intro: "<div class='tutorial-title'>Conclusion</div><div class='tutorial-body'>" +
          "Way to go you're a search pro! " +
          "We hope you find all kinds of great businesses in your area." +
          "<p>To more easily write, and publish reviews consider signing up to N49, or sharing a business with a friend.</p>" +
          "</div>"
        }
      ],
      'skipLabel': 'SKIP TOUR'
    });
    intro.onbeforechange(function(element) {
      if(element == document.querySelector('.sidebar')) {
        search.toggleFilters();
      }

    });
    intro.onafterchange(function(element) {
      if(this._currentStep === 6) {
        $('.introjs-nextbutton').hide();
      } else {
        $('.introjs-nextbutton').show();
      }
    });
    // conditions:
    // - should be desktop
    // - main content has to be loaded and shown
    // - user must not had seen the tutorial before
    // user.setTutorial(false);
    if(core.isMobile()) return;
    if(search.historyIframeControlled && !search.mainContentShown) return;
    if(user.hasSeenTutorial()) return;
    setTimeout(function() {
      intro.start();
    },5000);
    user.setTutorial(true);
  },

  initHeader: function() {
    // set user menu
    user.setStatus();
    if(user.isLoggedIn()) {
      $('.mobile-login-screen-buttons').hide();
      $('.user-logged-in-wrapper-mobile').show();
      // $('mobile-login-screen').css('min-height', '100%');
    } else {
      $('.mobile-login-screen').css('min-height', '100%');
    }
    // if only the header is loaded search-app is
    // loaded in an iframe and we have to send messages to parent
    if(window.location.href.match('widget=true')) post.open = true;
    var hasWhereCookie = false;
    if(store.getWhere()) {
      search.algolia.locationId(store.getWhere(), function(err, hit) {
        search.results.where.current = hit;
        suggestion.select(hit);
        hasWhereCookie = true;
      });
    }
    // history.replace(state, '', url.make(state, filters));
    // getGeo will set homeLocation which is the
    // default list of locations to show when
    // the where search box gets focus
    search.getGeo(function(hit) {
      if(!hasWhereCookie) {
        search.results.where.current = hit;
        suggestion.select(hit);
      }
    });
    suggestion.getTemplate();
    if(search.initialQuery === 'none') {
      state.what = 'none';
      var placeHolder = "Plumber, Starbucks, Self Storage";
      $(search.getWhatInputSelector()).val('');
    }
    user.getStatus();
    search.neededHeaderToggleButton();
    search.shouldCreateHamburgerMenu();
    search.attachHeaderEvents();
  },

  /** Initializes the UI aspect of filters in the sidebar */
  initFilters: function() {
    if(filters.minReviewRating) {
      var selector = '.search-filter-rating-option[data-option=' + filters.minReviewRating + ']';
      $(selector).addClass('search-filter-rating-option-selected');
      var overviewFilter = $(selector).clone();
      overviewFilter.addClass('filter-overview-stars');
      overviewFilter.find('.icon-close-btn').unwrap();
      $('.filters-selected-overview-wrap').append(overviewFilter);
    }
    // set initial into overview if any
    if(filters.minReviews) {
      search.updateMinReviewsOverviewFilter(filters.minReviews);
    }
    // init range slidebar
    var slider = $('#search-filter-reviews-slider')[0];
    noUiSlider.create(slider, {
      start: filters.minReviews || 0,
      connect: 'upper',
      range: {
  			'min': 0,
  			'max': 100
  		},
    });
    slider.noUiSlider.on('update', function(values) {
      $('#search-filter-reviews-min-value').text(Math.round(values[0]));
    });
    slider.noUiSlider.on('change', search.update.minReviews);
  },

  updateMinReviewsOverviewFilter: function(minReviews) {
    $('.filter-overview-minreviews').remove();
    if(minReviews !== 0) {
      var overviewFilter = $('<div class="filter-overview-minreviews"><i class="icon-close-btn"></i>Min. # Reviews: ' + minReviews + '</div>');
      $('.filters-selected-overview-wrap').append(overviewFilter);
    }
  },

  getGeo: function(callback) {
    var hps = window.innerWidth < 818 ? 4 : 25;
    var client = algoliasearch("46MRX88AYS", "b325826eb4f19b77e6fdc0ddf5cc3cde");
    var algolia = client.initIndex('geo_locations');
    var params = {
      hitsPerPage: 5,
      aroundLatLngViaIP: true,
      numericFilters: [
        '_geoloc.lat!=0',
        '_geoloc.lng!=0'
      ],
      facetFilters: [
        ["location_type:6",
        "location_type:3",
        "location_type:1"]
      ]
    };
    algolia.search(params, function(err, content){
      search.results.where.homeResults = content;
      search.results.where.lastSearch = content;
      callback(content.hits[0]);
    });
  },

  hit: {
    template: null,
    getTemplate: function(){
      $.get('/searchapp-static/templates/search-hit.hbs').done(function(res) {
        search.hit.template = Handlebars.compile(res);
      });
    },
    advertiser: {
      template: null,
      getTemplate: function(){
        $.get('/searchapp-static/templates/search-hit-advertiser.hbs').done(function(res) {
          search.hit.advertiser.template = Handlebars.compile(res);
        });
      }
    },
    category: {
      seeMore: function(id) {
        return " ...<a class='see-more' data-id='"+ id +"' href='#' onclick='return false'> See more</a>";
      },
      seeLess: function(id) {
        return "<a class='see-less' data-id='"+ id +"' href='#' onclick='return false'> See fewer</a>";
      }
    },
    description: {
      seeMore: function(id) {
        return "<a class='read-less' data-id='"+ id +"' href='#' onclick='return false'> Read less</a>";
      },
      seeLess: function(id) {
        return "<a class='read-more' data-id='"+ id +"' href='#' onclick='return false'> Read more</a>";
      }
    },
    review: {
      seeMore: function(id) {
        return "<a class='read-less-review' data-id='"+ id +"' href='#' onclick='return false'> Read less</a>";
      },
      seeLess: function(id) {
        return "<a class='read-more-review' data-id='"+ id +"' href='#' onclick='return false'> Read more</a>";
      }
    },
    getParent: function(element, idName, className) {
      var id = $(element).data('id');
      var parent = helpers.parentNode(element, idName, className);
      var hit = search.results.what.current[id];
      return {hit: hit, id: id, parent: parent};
    }
  },

  noResult: {
    template: null,
    getTemplate: function(){
      $.get('/searchapp-static/templates/search-no-results.hbs').done(function(res) {
        search.noResult.template = Handlebars.compile(res);
      });
    }
  },

  results: {
    where: {
      current: null,
      lastSearch: {}
    },
    what: {
      current: null,
    }
  },

  geo: {
    stringToObject: function(str) {
      if(typeof str !== 'string') return false;
      var latLng = str.split(',');
      if(latLng.length === 2) {
        return {lat: Number(latLng[0]), lng: Number(latLng[1])};
      }else{
        return false;
      }
    },
    objectToString: function(obj){
      return obj.hasOwnProperty('lat') ? obj.lat + "," + obj.lng : "";
    }
  },

  render: {
    suggestions: function(hits) {
      var outHTML = '';
      hits.slice(0,10).forEach(function(hit, i) {
        var sug = suggestion.template({
          hit: hit,
          index: i,
          last: (i === hits.length - 1)
        });
        outHTML += sug;
      });
      var selector = core.isMobile() ? '#search-mobile-where-suggestions' : '#where-results';
      $(selector).html(outHTML);
      // post.postCurrentHeightToParent();
      // post.height($('#where-results').height());
    },
    hits: function(hits) {
      var container = $('.search-results');
      var html = '', div, template;
      var idOffset = container.children().length;
      hits.forEach(function(hit, i) {
        template = hit.ad ? search.hit.advertiser.template : search.hit.template;
        hit.id += idOffset;
        div = template(hit);
        html += div;
      });
      container.append(html);
    },
    noResult: function() {
      if(state.params.page > 0) return;
      var div = search.noResult.template();
      $('.search-results').append(div);
      search.suggestionSearch.search();
    }
  },

  suggestionSearch: {
    search: function() {
      $('.suggestion-search').remove();
      var compiled = search.suggestionSearch.wrapperTemplate();
      $('.search-results').append(compiled);
      $('.suggestion-search-more-text').html('Search <b>' + state.what + '</b> in all locations');
      search.suggestionSearch.showLoader();
      try {
        search.algolia.suggestionSearch(function(result) {
          search.suggestionSearch.hideLoader();
          search.suggestionSearch.renderItems(result.hits);
          $('.suggestion-search-items').fadeIn(200);
        });
      } catch(e) {
        search.suggestionSearch.hideLoader();
      }
    },

    wrapperTemplate: null,
    itemTemplate: null,

    showLoader: function() {
      $('.suggestion-search-loader').fadeIn(200);
    },

    hideLoader: function() {
      $('.suggestion-search-loader').fadeOut(200);
    },

    renderItems: function(items) {
      var out = '';
      items.forEach(function(item) {
        out += search.suggestionSearch.itemTemplate(item);
      });
      $('.suggestion-search-items').html(out);
    },

    getTemplates: function() {
      $.get('/searchapp-static/templates/suggestion-search.hbs').done(function(res) {
        search.suggestionSearch.wrapperTemplate = Handlebars.compile(res);
      });
      $.get('/searchapp-static/templates/suggestion-search-item.hbs').done(function(res) {
        search.suggestionSearch.itemTemplate = Handlebars.compile(res);
      });
    },
  },

  algolia: {
    client: {
      geo: function() {
        var client = algoliasearch("46MRX88AYS", "b325826eb4f19b77e6fdc0ddf5cc3cde");
        var algolia = client.initIndex('geo_locations');
        return algolia;
      }
    },

    suggestionSearch: function(callback) {
      var data = {
        what: state.what,
        params: {
          aroundLatLng: '1, 1',
          insideBoundingBox: '-90,180,90,-180',
          hitsPerPage: 5
        }
      };
      $.ajax({
        url: '/searchapp-api/search',
        method: 'GET',
        data: data
      })
      .done(callback)
      .fail(function(err) {
        throw new Error(err);
      });
    },

    what: function() {
      var data = {
        what: state.what,
        params: state.params
      };
      search.secondaryHooks();
      search.startSearchLoadFX();
      $.ajax({
        url: '/searchapp-api/search',
        method: 'GET',
        data: data
      }).done(function(content) {
        var appliedRules = content.algoliaRaw.results[0].appliedRules;
        // run stats tracking in parallel
        try {
          // for stats tracking we need to recompute the id's to make them absolute
          // for result object that we're passing to track
          var hitsForTracking = [];
          if(state.params.page === 0) {
            hitsForTracking = content.hits;
          } else {
            hitsForTracking = content.hits.map(function(hit) {
              hit.id = Number(hit.id) + search.results.what.current.length;
              return hit;
            });
          }
          track.impression(hitsForTracking, state.what, search.results.where.current.location_name);
        } catch(e) {}
        search.stopSearchLoadFX();
        var html = '';
        if(state.params.page === 0) {
          search.results.what.current = [];
          $('.search-results').empty();
        }

        if(!content.hits || content.hits.length === 0) {
          content.hits = [];
          state.drained = true;
          search.render.noResult();
          $('#load-more-container').hide();
        } else {
          $('#load-more-container').show();
        }
        if(state.params.page === 0) {
          search.results.what.lastSearch = content;
          search.results.what.current = content.hits;
          search.clearMarkers();
        } else {
          search.results.what.current = search.results.what.current.concat(content.hits);
        }
        if(content.hits.length) search.render.hits(content.hits);
        if(state.params.page === 0) {
          filters.addCategories(content.algoliaRaw.results[0].facets.categories, state.what, appliedRules);
          filters.addBusinessGroups(content.algoliaRaw.results[0].facets.businessGroups);
          // filters.render.all();
        }

        search.loadMoreLock = false;
        if(!core.isMobile()) {
          search.reapplyCurrentLayout(false);
          search.addMarkers(content.hits);
          search.resizeMap();
        }
        state.changed = false;
        filters.render.overview();
        search.updateHistoryState(true);
        $('#search-criteria-total-results').text(content.algoliaRaw.results[0].nbHits);
        $('.search-criteria-overview-where').text(search.results.where.current.location_name);
        post.height($('.search-wrapper').height());
      }.bind(this));
    },
    where: function(where) {
      var hps = window.innerWidth < 818 ? 4 : 25;
      suggestion.currentHover = suggestion.minHover;
      var client = algoliasearch("46MRX88AYS", "b325826eb4f19b77e6fdc0ddf5cc3cde");
      var algolia = client.initIndex('geo_locations');
      var params = {
        hitsPerPage: 5,
        aroundLatLngViaIP: false,
        numericFilters: [
          '_geoloc.lat!=0',
          '_geoloc.lng!=0',
        ],
        highlightPreTag: "<b>",
        highlightPostTag: "</b>",
        facetFilters: [
          ["location_type:6",
           "location_type:3",
           "location_type:1"]
        ]
      };
      algolia.search(where, params, function(err, content) {
        var selector = core.isMobile() ? '#search-mobile-where-suggestions' : '#where-results';
        $(selector).empty();
        // remove content if doesnt have location viewport
        content.hits = content.hits.filter(function(hit) {
          return hit.location_viewport !== null;
        });
        search.results.where.lastSearch = content;
        search.render.suggestions(content.hits);
      });
    },
    locationId: function(id, callback) {
      var params = {
        hitsPerPage: 1,
        aroundLatLngViaIP: false,
        highlightPreTag: "<b>",
        highlightPostTag: "</b>",
      };
      var algolia = search.algolia.client.geo();
      algolia.getObject(id, function(err, content) {
        if(typeof callback === 'function') callback(err, content);
      });
    }
  },

  // Reference to Google Maps object
  map: null,
  searchOnMove: true,
  isScrollingMap: false,
  // Reference to marker clustering object
  markerClusterer: null,

  // Present markers diplayed on the map
  markers: [],

  // Initializes Google Maps though JavaScript API
  initMap: function(geoObj) {
    this.map = new window.google.maps.Map($('#search-map')[0], {
      center: geoObj,
      zoom: state.map.zoom || 8,
      mapTypeControl: false,
      zoomControl: false,
      maxZoom: 18,
      styles: [
        {
          "elementType": "labels.icon",
          "stylers": [
            { "visibility": "off" }
          ]
        }
      ]
    });
    this.markerClusterer = new MarkerClusterer(this.map, this.markers, {gridSize: 50, maxZoom: 15});
    var style = {
      textColor: '#ffffff',
      url: '/searchapp-static/images/map-cluster-icon.svg',
      height: 27,
      width: 27
    };
    this.markerClusterer.setStyles([style, style, style]);

    this.map.addListener('dragend', search.update.map.dragEnd);
    google.maps.event.addListener(this.markerClusterer, 'clusterclick', function(c){
      //because there is an event in the markerClusterer module that sets the bounds and Zoom/
      // of the map, we need to wait until that is finished before making a new search
      if(search.searchOnMove) {
        state.method = search.method = 'map';
        setTimeout(search.update.map.search, 100);
      }else {
        search.update.map.showRedoButton();
      }
    });
  },

  // Synchronizes the search hits that were retrived &
  // rendered on server with AJAX search
  synchronizeInitialHits: function() {
    search.results.what.current = search.initialHits;
    if(core.isMobile()) return false;
    search.addMarkers(search.initialHits);
    search.resizeMap();
    track.impression(search.results.what.current, state.what, search.results.where.current.location_name);
  },

  resizeMap: function(){
    if(search.method === 'where') {
      if(search.markers.length){
        search.fitBoundsToMarkers();
      }else{
        search.centerMap();
      }
    }else if(search.method === 'map'){

    }else if(search.method === 'history') {
      if(state.params.insideBoundingBox) {
        search.centerMap();
        search.zoomMap();
      }
    }
  },
  mapMaxZoom: function() {
    var maxZoom = search.method === "map" ? 21 : 18;
    if(search.map.getZoom() > maxZoom) {
      search.map.setZoom(maxZoom);
    }
  },
  centerMap: function() {
    search.map.setCenter(
      search.geo.stringToObject(state.params.aroundLatLng) || search.results.where.current._geoloc
    );
  },
  zoomMap: function(){
    var zoom = Math.min(18, state.map.zoom);
    search.map.setZoom(zoom);
  },
  centerWithZoom: function() {
    if(!state.params.aroundLatLng) return;
    search.map.setCenter(search.geo.stringToObject(state.params.aroundLatLng));
    search.zoomMap();
  },
  fitBoundsToMarkers: function () {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < search.markers.length; i += 1) {
      if(search.markers[i]) {
        bounds.extend( search.markers[i].getPosition() );
      }
    }
    search.map.fitBounds(bounds);
    return bounds;
  },
  // Takes an array of hits & adds markers for each company
  addMarkers: function(hits) {
    // we need to put a fake marker that is in the center of the Map
    // if we do not want to show the marker. This is because marker clusterer
    // need to have real markers to work and our hits in the search-result
    // are connected with markers via ID.
    var geo;
    var fakeGeo = (state.params.aroundLatLng || search.addMarkers.fakeAroundLatLng).split(',');
    fakeGeo = {lat: Number(fakeGeo[0]), lng: Number(fakeGeo[1])};
    var indexOffset = search.markers.length;
    if(!Array.isArray(hits)) {
      hits = [];
    }
    hits.forEach(function(hit, index) {
      index += indexOffset;
      var markerIcon = '/searchapp-static/images/map-marker-counter.svg';
      var labelContent = '<i class="' + ((hit.starAverage === 0) ? 'icon-emptystar' : 'icon-fullstar') +
      ' star-icon-any star-active star-icon-in-marker"></i> ' + hit.starAverage;
      if(hit.ad) {
        markerIcon = '/searchapp-static/images/map-marker-counter-paid.svg';
        labelContent = '<i style="color: white;" class="' + ((hit.starAverage === 0) ? 'icon-emptystar' : 'icon-fullstar') +
        ' star-icon-any star-active star-icon-in-marker"></i> ' + hit.starAverage;
      }
      var addRealMarker = true;
      if(!hit.showMarker) addRealMarker = false;
      geo = [].concat(hit._geoloc);
      if(!geo.length) addRealMarker = false;
      if(search.method === "where" && !search.inLocationBounds(geo[0])) addRealMarker = false;
      geo = geo[0];
      if(typeof geo.lat !== 'number' && typeof geo.lng !== 'number') addRealMarker = false;
        var marker = new MarkerWithLabel({
          position: addRealMarker ? geo : fakeGeo,
          labelContent: labelContent,
          labelClass: 'map-marker-label',
          icon: markerIcon,
          labelAnchor: new google.maps.Point(18, 28)
        });
        marker.hitIndex = index;
        marker.addListener('click', search.update.hitsScrollPosition);
        search.markers.push(marker);
        var showInfoWindow = search.constructInfoWindowFunction(marker, hit);
        marker.addListener('click', showInfoWindow);
      if(!addRealMarker){
        marker.visible = false;
      }

    });
    search.markerClusterer.addMarkers(search.markers);
  },

  inLocationBounds: function(geo) {
    if(!search.results.where.current.location_viewport){
      var latUnit = 180 / 40075;
      var lngUnit = 360 / 40075;
      var clat = search.results.where.current._geoloc.lat;
      var clng = search.results.where.current._geoloc.lng;
      var dRlat = 25 * latUnit;
      var dRlng = 25 * lngUnit;
      var dR = Math.sqrt(dRlat * dRlat + dRlng * dRlng);
      var dlat = Math.abs(geo.lat - clat);
      var dlng = Math.abs(geo.lng - clng);
      var D = Math.sqrt(dlat*dlat + dlng*dlng);
      return D < dR;
    }else {
      var NE = {
        lat: search.results.where.current.location_viewport.northeast.lat,
        lng: search.results.where.current.location_viewport.northeast.lng
      };
      var SW = {
        lat: search.results.where.current.location_viewport.southwest.lat,
        lng: search.results.where.current.location_viewport.southwest.lng
      };
      if(geo.lat > SW.lat && geo.lat < NE.lat && geo.lng > SW.lng && geo.lng < NE.lng) {
        return true;
      } else {
        return false;
      }
    }
  },

  // Creates an info window and returns a function that shows it
  infoWindows: [],
  constructInfoWindowFunction: function(marker, hit) {

    var infowindow = new google.maps.InfoWindow({
      content: search._mapTooltip(hit),
      maxWidth: 400
    });

    google.maps.event.addListener(infowindow,'closeclick',function(){
      var searchHit = $('.search-results').children()[hit.id];
      search.selectHit.call(searchHit);
    });

    search.infoWindows.push(infowindow);
    return function() {
      // we need to track this event from here because
      // you can't map this kind of event from class-> action map

      search.closeAllInfoWindows();
      infowindow.open(search.map, marker);
    };
  },

  closeAllInfoWindows: function() {
    search.infoWindows.map(function(infowindow) {
      infowindow.close();
    });
  },

  _mapTooltip: null,
  getMapTooltipTemplate: function(callback) {
    return $.get('/searchapp-static/templates/map-tooltip.hbs').done(function(template) {
      search._mapTooltip = Handlebars.compile(template);
      return callback.call(search);
    });
  },
  // Loads tooltip template if one wasn't loaded before,
  // compiles template into HTML and returns it
  clearMarkers: function() {
    this.markers = [];
    this.infoWindows = [];
    if(!this.markerClusterer) return;
    this.markerClusterer.clearMarkers();
  },

  toggleCategory: function() {
    var filterName = $(this).data('filterName');
    var filterValue = $(this).data('filterValue');
    var cat = filters.savedCategories[filterName];
    if(cat) {
      delete filters.savedCategories[filterName];
    } else {
      filters.savedCategories[filterName] = filters.categories[filterName];
    }
    filters.ruleContexts = 'categories-disabled';
    filters.render.all();
    search.updateHistoryState();
  },

  chooseCategoryFromHit: function() {
    var filterName = $(this).text();
    if(!filters.savedCategories.hasOwnProperty(filterName)) {
      if(filters.categories[filterName]) {
        filters.savedCategories[filterName] = filters.categories[filterName];
      }
    } else {
      delete filters.savedCategories[filterName];
    }
    search.updateHistoryState();
    return false;
  },

  toggleBusinessGroup: function() {
    var filterName = $(this).data('filterName');
    var filterValue = $(this).data('filterValue');
    if(!filters.savedBusinessGroups.hasOwnProperty(filterName)) {
      filters.savedBusinessGroups[filterName] = filters.businessGroups[filterName];
    }else {
      delete filters.savedBusinessGroups[filterName];
    }
    search.updateHistoryState();
  },

  // Fires when search wrapper is being scrolled,
  // used to load more results when scroll position is at
  // the bottom of the element
  loadMoreLock: false,

  highlightHit: function(event, alternativeTarget) {
    if(search.hitSelected && !alternativeTarget) return;
    search.closeAllInfoWindows();
    var target = alternativeTarget || event.currentTarget;
    var hitId = Number($(target).attr('data-id'));
    if(core.isMobile()) return;
    // only for desktop
    if(!search.markers[hitId] || !search.markers[hitId].visible) return;
    if(search.markers[hitId] && search.markers[hitId].internalPosition) {
      search.infoWindows[hitId].open(search.map, search.markers[hitId]);
    } else if(search.markers[hitId].visible){
      var geo = search.markers[hitId].position;
      var newMarker = new google.maps.Marker({map:search.map, visible: false, position: geo});
      search.infoWindows[hitId].open(search.map, newMarker);
    }
  },

  hitSelected: false,
  selectHit: function(e) {
    if($(this).hasClass('search-hit-wrapper-selected')) {
      $(this).removeClass('search-hit-wrapper-selected');
      search.hitSelected = false;
    } else {
      $('.search-hit-wrapper-selected').removeClass('search-hit-wrapper-selected');
      search.highlightHit.call(this, event, $(this).find('.search-hit'));
      $(this).addClass('search-hit-wrapper-selected');
      search.hitSelected = true;
    }
  },

  // To make this functionality portable make .search-loader an argument
  searchLoadFXShouldRun: false,

  startSearchLoadFX: function() {
    $('.search-wrapper-fx-lock').css({
      top: $('header').height(),
      bottom: 0,
      width: $('.search-wrapper').css('width')
    });
    $('.search-wrapper-fx-lock').fadeIn(100);
  },

  stopSearchLoadFX: function() {
    $('.search-wrapper-fx-lock').fadeOut();
  },

  mobileSearchInputsOn: false,
  toggleMobileSearchInputs: function() {
    if(search.mobileSearchInputsOn) {
      $('.screen-lock').fadeOut(100);
      $('.search-mobile-inputs-wrapper').fadeOut(100);
    } else {
      $('.screen-lock').fadeIn(100);
      $('.search-mobile-inputs-wrapper').fadeIn(100);
    }
    search.mobileSearchInputsOn = !search.mobileSearchInputsOn;
  },

  layoutStateCurrent: 'large',
  fxTiming: 150,
  layoutStateSmall: function(withFX) {
    if(!search.filtersOpened) {
      $('.search-wrapper-toggle-icon').removeClass('search-wrapper-toggle-flipped');
    }
    var getFXTiming = function() {
      return withFX ? search.fxTiming : 0;
    };
    $('.search-hit-contact-text').css('maxWidth', 'none');
    $('.search-wrapper').animate({
      'width': '370px'
    }, getFXTiming(), function() {
      search.triggerGoogleMapsResize();
    });
    search.adjustLayoutToggleButton({
      searchWrapperWidth: 370
    });
    $('.search-hit-image').animate({
      width: '55px',
      minHeight: '42px'
    }, getFXTiming());
    // layout changes
    $('.search-hit-top').removeClass('pull-left').css('width', '100%');
    $('.search-hit-contact').removeClass('pull-right').css({
      width: '100%',
      marginLeft: '75px',
      marginTop: '10px'
    });
    $('.search-hit-rating .star-icon-any').animate({
      fontSize: '12px'
    }, getFXTiming());
    $('.search-hit-description').fadeOut(getFXTiming());
    search.layoutStateCurrent = 'small';
    $('.search-hit-latest-review').hide(100);
    search.layoutFitMap();
  },

  layoutStateLarge: function(withFX) {
    $('.search-wrapper-toggle-icon').addClass('search-wrapper-toggle-flipped');
    var getFXTiming = function() {
      return withFX ? search.fxTiming : 0;
    };
    $('.search-hit-contact-text').css('maxWidth', '85px');
    $('.search-wrapper').animate({
      'width': '600px'
    }, getFXTiming(), function() {
      search.triggerGoogleMapsResize();
    });
    search.adjustLayoutToggleButton({
      searchWrapperWidth: 600
    });
    // resize logos to match the large layout
    $('.search-hit-image').animate({
      width: '125px',
      minHeight: '88px'
    }, getFXTiming());
    // layout changes
    $('.search-hit-top').addClass('pull-left').css('width', '80%');
    $('.search-hit-contact').addClass('pull-right').css({
      width: '20%',
      margin: '0'
    });
    $('.search-hit-rating .star-icon-any').animate({
      fontSize: '15px'
    }, getFXTiming());
    $('.search-hit-description').fadeIn(getFXTiming());
    search.layoutStateCurrent = 'large';
    $('.search-hit-latest-review').show(100);
    search.layoutFitMap();
  },

  layoutStateToggleByArrow: function() {
    if(search.layoutStateCurrent === 'small') {
      if(search.filtersOpened) {
        search.toggleFilters();
      }
      search.layoutStateLarge(true);
    } else {
      search.layoutStateSmall(true);
    }
  },

  layoutStateMobile: function() {
    // it's implied that map is not in this view
    $('.search-right-map').css('display', 'none');
    $('.sidebar-header').remove();
    $('.search-criteria-mobile-magnifier').css('display', 'block');
    $('.search-filters-mobile').css('display', 'block');
    $('header').hide();
    $('.header-mobile').css('display', 'block');
    $('.sidebar')
    .removeClass('pull-left')
    .removeClass('sidebar-collapsed')
    .css({
      position: 'fixed',
      zIndex: 8,
      display: 'none',
      width: '100%',
      top: '192px',
      paddingBottom: '200px'
    });
    $('.sidebar-filters-align').css({
      margin: '0 auto',
      width: '90%'
    });
    $('.search-filter-rating-option').css({
      margin: '0 auto',
      width: '90%'
    });
    $('.search-filter-rating-option-remove .icon-close-btn').css({
      fontSize: '20px',
      position: 'relative',
      top: '4px'
    });
    $('.search-filter-rating-option .star-icon-any').css({
      fontSize: '25px'
    });
    $('#search-criteria-total').css({
      paddingTop: '5px'
    });
    $('.search-top-mobile-trigger').css({
      overflow: 'hidden',
      height: '55px'
    });
    $('.search-top').css({
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 8,
      top: search.headerHeight() + 'px'
    });
    $('.search-wrapper').css({
      'margin-left': '0',
      width: '100% !important',
      'box-shadow': 'none'
    }).removeClass('pull-left');
    /*$('.search-results').css({
      'margin-top': '120px',
    });*/
    $('.search-app').removeClass('fill-respect-header').removeClass('fill');
    $('.fill-respect-header').css('top', '37px');
    $('.header-mobile').css({
      'position': 'fixed',
      left: 0,
      right: 0,
      top: 0,
      zIndex: 7
    });
    $('.search-wrapper-toggle-wrap').css('display', 'none');
    $('.filters-selected-overview-wrap').css({
      'margin-bottom': '0'
    });
    setInterval(function() {
      $('.search-results').css({
        marginTop: $('.header-mobile').height() + $('.search-top').height()
      });
      $('.sidebar').css('top', $('.header-mobile').height() + $('.search-top').height() + 'px');
    }, 200);
  },

  layoutStateFiltersOn: function() {
    if(core.isMobile()) return;
    $('.search-wrapper').animate({
      'margin-left': '250px'
    }, 0);
    search.layoutFitMap();
  },

  layoutStateFiltersOff: function() {
    if(core.isMobile()) return;
    $('.search-wrapper').animate({
      'margin-left': '44px'
    }, 0);
    search.layoutFitMap();
  },

  layoutFitMap: function() {
    var filtersWidth = search.filtersOpened ? 250 : 44;
    var searchResultsWidth = (search.layoutStateCurrent === 'large') ? 600 : 370;
    var overallWidth = filtersWidth + searchResultsWidth;
    $('.search-right-map').animate({
      'left': overallWidth + 'px',
    }, 100);
  },

  toggleLayoutStateFilters: function() {
    if($('.search-wrapper').css('margin-left') === '250px') {
      return search.layoutStateFiltersOff();
    } else {
      return search.layoutStateFiltersOn();
    }
  },

  // Needs to be called after each result render
  reapplyCurrentLayout: function(withFX) {
    var callables = {
      large: function() {
        search.layoutStateLarge(withFX);
      },
      small: function() {
        search.layoutStateSmall(withFX);
      },
      different: function() {
        return console.error('No handler for this layout.');
      }
    };
    return (callables[search.layoutStateCurrent] || callables['different'])();
  },

  mobileLoginScreenOn: false,
  toggleLoginScreenMobile: function() {
    if(!search.mobileLoginScreenOn) {
      $('.mobile-login-screen').fadeIn(100);
      $('.header-buttons-collapsed-toggle.mobile.pull-right i')
      .removeClass('icon-hamburgermenu').addClass('icon-close-btn');
    } else {
      $('.mobile-login-screen').fadeOut(100);
      $('.header-buttons-collapsed-toggle.mobile.pull-right i')
      .removeClass('icon-close-btn').addClass('icon-hamburgermenu');
    }
    // search.mobileLoginScreenOn = !search.mobileLoginScreenOn;
    search.toggleMobileMenuStateIcon();
  },

  toggleMobileMenuStateIcon: function() {
    var on = false;
    if(search.mobileLoginScreenOn) on = true;
    if(search.loggedinWidgetShown) on = true;
    if(on) {
      return $('.header-mobile-menu-icon').html('<i class="icon-close-btn"></i>');
    } else {
      return $('.header-mobile-menu-icon').html('<i class="icon-hamburgermenu"></i>');
    }
  },

  loggedinWidgetShown: false,
  toggleLoggedInWidget: function(alternativeTargetSelector) {
   if($(window).width() > 804) {
     if(!search.loggedinWidgetShown) {
       var targetSelector = alternativeTargetSelector ? alternativeTargetSelector : '#header-user-menu-button';
       var offset = $(targetSelector).offset();
       var selfWidth = $('.user-logged-in-wrapper').width();
       var buttonWidth = $(targetSelector).width() + parseInt($(targetSelector).css('padding-right'));
       var containerLeft = offset.left - selfWidth + buttonWidth;
       post.height($('.user-logged-in-wrapper').height());
       $('.user-logged-in-wrapper').fadeIn(100);
     } else {
       $('.user-logged-in-wrapper').fadeOut(100, function(){
         post.height(0);
       });
     }
   } else {
     if(!search.loggedinWidgetShown) {
       $('.mobile-login-screen').css('top', search.headerHeight() + 'px');
       $('.mobile-login-screen').addClass('fill screen-lock').fadeIn(100);
       document.body.style.overflow = 'hidden';
       document.body.style.position = 'fixed';
       document.body.style.height = '100%';
       post.height(window.parent.innerHeight +5)
     } else {
       $('.mobile-login-screen').fadeOut(100);
       document.body.style.overflow = 'initial';
       document.body.style.position = 'relative';
       document.body.style.height = 'auto';
       post.height($('.header-mobile').height())
     }
   }
   search.loggedinWidgetShown = !search.loggedinWidgetShown;
   search.toggleMobileMenuStateIcon();
 },

  // Login state handling
  decideToggleTarget: function() {
    // disabled search hits from scolling if
    // mobile menu is open
    // if(document.body.style.overflow == 'hidden') {
    //   document.body.style.overflow = 'scroll';
    // } else {
    //   document.body.style.overflow = 'hidden';
    // }

    if(user.isLoggedIn()) {
      search.toggleLoggedInWidget();
    } else {
      search.toggleLoginScreenMobile();
    }
  },

  filterRatingOptionClicked: function(e) {
    if($(this).hasClass('search-filter-rating-option-selected')) {
      var closeElement = $(".search-filter-rating-option-remove", this)[0];
      search.filterRatingOptionRemoveClicked.call(closeElement, e);
    } else {
      $('.search-filter-rating-option').removeClass('search-filter-rating-option-selected');
      $(this).addClass('search-filter-rating-option-selected');
      $('.filter-overview-stars').remove();
      var overviewFilter = $(this).clone();
      overviewFilter.addClass('filter-overview-stars');
      overviewFilter.find('.icon-close-btn').unwrap();
      $('.filters-selected-overview-wrap').append(overviewFilter);
      search.update.reviewRating.call(this, parseInt($(this).attr('data-option'), 10));
    }
  },

  filterRatingOptionRemoveClicked: function(e) {
    if($(this).is('.filter-overview-stars')) {
      $('.search-filter-rating-option').removeClass('search-filter-rating-option-selected');
    } else {
      $(this).parent().removeClass('search-filter-rating-option-selected');
    }
    $('.filter-overview-stars').remove();
    search.update.reviewRating.call(this, 0);
    return false;
  },

  mouseMovedOutOfHits: function() {
    if(!search.hitSelected) {
      return search.closeAllInfoWindows();
    }
  },

  flushAllFilters: function(skipSearch) {
    $('.filters-selected-overview-wrap').hide(100, function() {
      $('.filters-selected-overview-wrap').html('').show();
    });
    $('.search-filter-rating-option-selected').removeClass('search-filter-rating-option-selected');
    $('#search-filter-reviews-slider')[0].noUiSlider.set(0);
    filters.clear();
    filters.render.all();
    state.params.ruleContexts = filters.make.ruleContexts();
    state.params.numericFilters = filters.make.numeric();
    if(skipSearch !== true) search.updateHistoryState();
  },

  triggerGoogleMapsResize: function() {
    google.maps.event.trigger(search.map, 'resize');
  },

  // button that shows up popup box with
  // either "add a business" and "sing in/ sign up"
  // or user info widget
  // only when window is resized to smaller than 1280px
  neededHeaderToggleButton: function() {
    if(user.isLoggedIn()) {
      $('.header-buttons-collapsed-toggle').html('<img src="' + user.data.userInfo.n49Avatar + '" width="32" height="32" /><i class="icon-more-btn"></i>');
    } else {
      $('.header-buttons-collapsed-toggle').html('<i class="icon-hamburgermenu"></i>');
    }
  },

  didScroll: false,
  lastScrollTop: 0,
  headerHeight: function() {
    if(core.isMobile) return $('.header-mobile').height();
    return $('header').height();
  },
  shouldHideMobileHeader: function() {
    var st = $(window).scrollTop();
    var scrollDiff = st - search.lastScrollTop;
    if(st <= 0) {
      // show if scrolled to top quickly
      $('.header-mobile').css('top', 0);
      $('.search-top').css('top', search.headerHeight() + 'px');
    } else {
      var headerMobileHeight = $('.header-mobile').height();
      var searchTopHeight = $('.search-top').height();
      var combinedHeights = headerMobileHeight + searchTopHeight;
      if(scrollDiff > 30) {
        //scrolled down, hide with fx
        $('.header-mobile').animate({
          top:  (-(headerMobileHeight)) + 'px'
        }, 100);
        $('.search-top').animate({
          top:  -combinedHeights + 'px'
        }, 100);
      } else if (scrollDiff < -30) {
        $('.header-mobile').animate({
          top: 0
        }, 100);
        $('.search-top').animate({
          top: headerMobileHeight + 'px'
        }, 100);
        $('.search-wrapper').animate({
          top: 0
        });
      }
    }
    search.lastScrollTop = st;
  },

  planetEarthSearchFallback: function() {
    var algolia = search.algolia.client.geo();
    algolia.getObject(3141592653, function(err, result) {
      $('.search-criteria-overview-where').text(result.location_name);
      search.results.where.current = result;
      search.validateLocation(result);
      suggestion.select(result);
      search.flushAllFilters(true);
      url.whereId = result.objectID;
      url.whereSlug = result.location_name;
      search.updateHistoryState();
    });
    return false;
  },

  trackClickOnHitClick: function(event) {
    try {
      track.click(
        search.results.what.current,
        state.what,
        search.results.where.current.location_name,
        event
      );
    } catch(e) {}
  },

  searchHitContentListItemClicked: function(event) {
    // event.preventDefault();
    // url that will need to be called
    var partnerBid = typeof n49Business !== 'undefined' ? n49Business.partnerBid : $(event.currentTarget).parents('.search-hit').attr('data-partnerbid');
    if(!partnerBid || partnerBid.length < 1) return false;
    var type = $(event.currentTarget).attr('data-type');
    if(!type || type.length < 1) return false;
    search.showPartnersPopupWithIframe(partnerBid, type);
    return false;
  },

  showPartnersPopupWithIframe: function(partnerBid, type) {
    $('body').css('overflow', 'hidden');
    var url = 'https://lists.yext-pub.com/lists?pid=9HGlgc3xX8&yextId=' + partnerBid + '&template=' + type;
    var raw = '<iframe class="partners-popup-iframe" src="' + url + '" width="100%" height="100%" frameborder="0" scrolling="no"></iframe>';
    $('.partners-popup').html(raw);
    $('.partners-popup-screen-lock').fadeIn(100);
  },

  closePartnersPopup: function() {
    $('body').css('overflow', 'auto');
    $('.partners-popup').html('');
    $('.partners-popup-screen-lock').fadeOut(100);
  }

};

// alert(navigator.userAgent);
window.addEventListener('error', function(err) {
  // alert(err.message + ' line: ' + err.lineno)
  // $.ajax({
  //   url: '/searchapp-api/remote-logging',
  //   method: 'post',
  //   data: err
  // })
});

window.search = search;
