'use strict';

var $ = require('../../../bower_components/jquery/dist/jquery');
var Handlebars = require('../../../bower_components/handlebars/handlebars');
var handlebarsHelpers = require('../../../handlebars-helpers');
var core = require('../core');
var post = require('./post');

var suggestion = {
  minHover: 0,
  maxHover: 10,
  currentHover: 0,
  selected: null,
  select: function(hit, callback) {
    var selector = core.isMobile() ? '#search-mobile-input-where' : '#search-header-input-where';
    $(selector).val(hit && hit.location_name ? hit.location_name: '');
    post.sendWhere(hit);
    return hit;
  },
  traverse: function(num) {
    $("#where-results .suggestion:nth-child(" + this.currentHover + ")")
    .toggleClass("suggestion-hover");
    this.currentHover += num;
    if(this.currentHover < this.minHover) this.currentHover = this.minHover;
    if(this.currentHover > this.maxHover) this.currentHover = this.maxHover;
    this.selected = $("#where-results .suggestion:nth-child(" + this.currentHover + ")")[0];
    $("#where-results .suggestion:nth-child(" + this.currentHover + ")")
    .toggleClass("suggestion-hover");
  },
  parent: $('#where-results'),
  what: $('#search-header-input-what'),
  where: $('#search-header-input-where'),
  template: null,
  getTemplate: function(callback){
    $.get('/searchapp-static/templates/search-suggestion.hbs').done(function(res) {
      this.template = Handlebars.compile(res);
      if(typeof callback === 'function') callback(this.template);
    }.bind(this));
  },
};

module.exports = suggestion;
