'use strict';

var $ = require('../../../bower_components/jquery/dist/jquery');
var core = require('../core');
var post = require('./post');
var url = require('../../../server/modules/urlQuery');

var user = {
  data: null,
  hasSeenTutorial: function() {
    return document.cookie.match(/hasSeenTutorial=true/);
  },
  setTutorial: function(bool) {
    var domain = window.location.host.replace(/www/, '');
    document.cookie = "hasSeenTutorial=" + bool + ";domain=" + domain + ";path=/" + "";
  },
  signin: function() {
    if(post.open) {
      return post.postMessageToParent({type: 'SIGN_IN'});
    }
    parent.location.href = "/signup?return_to=" + window.location.href.replace(/&widget=true/,'');
  },
  signout: function() {
    if(post.open) return post.signOut();
    parent.top.location.href = "/logout.php?return_to=" + window.location.href;
  },
  getStatus: function() {
    if(!user.data) return;
    var cookie = document.cookie.match(/n49login=({.+})/);
    if(cookie) {
      user.data = JSON.parse(cookie[1]);
      $('.user-logged-in-name').text(user.data.userInfo.n49Username);
      $('#header-sign-in-button').css('display', 'none');
      $('#header-user-menu-button').css('display', 'block');
    }
  },
  isLoggedIn: function() {
    return !!user.data;
  },
  setStatus: function() {
    if(!user.data) {
      // look for cookie if no user.data-id
      var cookie = url.stringToObject(document.cookie, ';', '=');
      // check if cookie has userMenu
      if(cookie.userMenu) {
        user.data = JSON.parse(cookie.userMenu);
      } else {
        return;
      }
    }
    user.isAdmin = false;
    user.data.userActions.forEach(function(obj) {
      if(obj.title === 'Profile') {
        return $('#user-profile').css('display', 'block');
      }
      if(obj.title === 'Log+out') {
        return $('#user-log-out').css('display', 'block');
      }
      if(obj.title === 'Profile') {
        return $('#user-settings').css('display', 'block');
      }
      if(obj.title === 'Reviews') {
        $('.user-menu-item-count','.user-reviews').text(obj.count);
        return $('#user-reviews').css('display', 'block');
      }
      if(obj.title === 'Favourites') {
        $('.user-menu-item-count','.user-favourites').text(obj.count);
        return $('#user-favourites').css('display', 'block');
      }
      if(obj.title === 'Login+As') {
        user.isAdmin = true;

        return $('#user-log-in-as').css('display', 'block');
      }
      if(obj.title === 'Latest+Activity') {
        return $('#user-latest-activity').css('display', 'block');
      }
      if(obj.title === "Businessses") {
        $('.user-menu-item-count','.user-edit-businesses').text(obj.count);
        return $('#user-edit-businesses').css('display', 'block');
      }
    });
    if(!user.isAdmin) {
      $('#user-log-in-as').remove();
      $('#log-in-as-container').remove();
      $('#user-latest-activity').remove();
      $('#user-payed-search').remove();
    } else {
      $('#user-payed-search').css('display', 'block');
    }
    $('#header-user-menu-button img').attr('src', user.data.userInfo.n49Avatar);
    $('.user-logged-in-userpic img').attr('src', user.data.userInfo.n49Avatar);
    $('.user-logged-in-name').text(user.data.userInfo.n49Username);
    $('.user-logged-link').attr("href", document.location.origin + "/member/" + user.data.userInfo.n49Username + "/profile/");
    // check if href has dashboard in it
    var special_url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
    if(special_url.includes('dashboard')) {
      $('.user-profile').attr("href", "https://dashboard.n49.com/edit-user?mlogin=" + user.data.userInfo.n49Username);
    }
    else {
      $('.user-profile').attr("href", "https://dashboard.n49.com/edit-user?mlogin=" + user.data.userInfo.n49Username);

      // $('.user-profile').attr("href", document.location.origin + "/member/" + user.data.userInfo.n49Username + "/edit/");
    }
    $('#user-edit-businesses').attr("href", "https://dashboard.n49.com/my-businesses");
    $('#header-sign-in-button').css('display', 'none');
    $('#header-user-menu-button').css('display', 'block');
  },
  settings: function(profile) {
    // if(post.open) return post.userSettings(user.data.userInfo.n49Username);
    parent.location.href = "/member/" + user.data.userInfo.n49Username + "/edit/";
  },
  reviews: function() {
    if(post.open) return post.userReviews(user.data.userInfo.n49Username);
    parent.location.href = "/member/" + user.data.userInfo.n49Username + "/" + "reviews";
  },
  favourites: function() {
    if(post.open) return post.userFavourites(user.data.userInfo.n49Username);
    parent.location.href = "/member/" + user.data.userInfo.n49Username + "/" + "favourites/listings/";
  },
  logInAs: {
    toggleMenu: function() {
      $(".log-in-as-container").toggle({
        duration: 16,
        complete: function() {
          if(post.open && !core.isMobile()) {
            post.height($(".user-logged-in-wrapper").height());
          }
        }
      });
    },
    login: function() {
      var fakeUser = $(".user-log-in-as-username").val();
      if(post.open) return post.logInAs(user.data.userInfo.n49Username, fakeUser);
      user.signInAs(fakeUser);
    }
  },
  signInAs: function(fakeUser) {
    var statsUrl = '/libs/JSON/signInAs.php';
    var data = {username: fakeUser};
    $.ajax({
      url: statsUrl,
      data: data,
      type: "POST"
    })
    .done(function($data) {
      window.location.reload();
    });
  },
  latestActivity: function() {
    if(post.open) return post.latestActivity();
    parent.location.href = "/latest_activity/";
  },
  forCompanies: function() {
    if(post.open) return post.forCompanies();
    parent.location.href = "https://n49.net/pricing";
  },
  addBusiness: function() {
    if(post.open) return post.addBusiness();
    parent.location.href = "https://www.n49.com/add-biz/";
  },
  addReview: function() {
    parent.location.href = "https://www.n49.com/add-review";
  },
  editBusinesses: function() {
    // if(post.open) return post.editBusinesses();
    parent.location.href = "https://dashboard.n49.com/my-businesses";
  },
  payedSearch: function() {
    if(!user.isAdmin) return;
    if(post.open) return post.payedSearch();
    var params;
    if(window.location.search) {
      params = "&paying=1";
    } else {
      params = "paying=1";
    }
    parent.location.href = window.location.href + params;
  }
};
window.user = user;
module.exports = user;
