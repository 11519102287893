// An Object with general helpers to be used anywhere you include it.
// Add more functions if they are general enough.
var map = [
  { encode: new Map([ ['0', 'z'], ['1', 'R'], ['2', 'y'], ['3', 'S'], ['4', 'j'], ['5', 'U'], ['6', 'f'], ['7', 'W'], ['8', 'c'], ['9', 'M'] ]) },
  { encode: new Map([ ['0', 'B'], ['1', 'n'], ['2', 'A'], ['3', 'x'], ['4', 'D'], ['5', 'a'], ['6', 'C'], ['7', 's'], ['8', 'j'], ['9', 'Y'] ]) },
  { encode: new Map([ ['0', 'i'], ['1', 'e'], ['2', 'T'], ['3', 'v'], ['4', 'b'], ['5', 'D'], ['6', 'B'], ['7', 'p'], ['8', 'n'], ['9', 'c'] ]) },
  { encode: new Map([ ['0', 'q'], ['1', 'R'], ['2', 'y'], ['3', 'S'], ['4', 'j'], ['5', 'U'], ['6', 'f'], ['7', 'W'], ['8', 'c'], ['9', 'M'] ]) },
  { encode: new Map([ ['0', 'G'], ['1', 'p'], ['2', 'o'], ['3', 'j'], ['4', 'r'], ['5', 'a'], ['6', 'O'], ['7', 'D'], ['8', 'Y'], ['9', 'n'] ]) },
  { encode: new Map([ ['0', 'L'], ['1', 'T'], ['2', 'h'], ['3', 'W'], ['4', 'J'], ['5', 'k'], ['6', 'M'], ['7', 'O'], ['8', 'A'], ['9', 'x'] ]) },
  { encode: new Map([ ['0', 'Y'], ['1', 'D'], ['2', 'H'], ['3', 'P'], ['4', 'g'], ['5', 'a'], ['6', 'w'], ['7', 'x'], ['8', 'K'], ['9', 'T'] ]) }
];
for (var i = 0; i < map.length; i++) {
  map[i].decode = new Map();
  map[i].encode.forEach(function (value, key) {
    map[i].decode.set(value, key);
  });
}

var helpers = {
  isInList: function(val, list, filterKey){
    for(var i = 0; i < list.length; i += 1){
      if(list[i][filterKey] === val[filterKey]){
        return {index: i};
      }
    }
    return false;
  },
  parentNode: function(el, id, clas) {
    id = id || Math.random();
    clas = clas || Math.random();
    if(!el.parentNode) return false;
    console.log(el.parentNode.id, el.parentNode.className);
    if(el.parentNode.id === id || el.parentNode.classList.contains(clas)){
      return el.parentNode;
    }else {
      return helpers.parentNode(el.parentNode, id, clas);
    }
  },
  checkNested: function(obj /*, level1, level2, ... levelN*/) {
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  },
  encodePageNumber: function (n) {
    var result = '';
    n = parseInt(n);
    if (isNaN(n) || n < 0) {
      n = 0;
    }
    var str = n.toString();
    var i;
    for (i = (7 - str.length); i > 0; i--) {
      str = '0' + str;
    }
    for (i = 0; i < str.length; i++) {
      var mapIndex = i;
      if (i >= map.length) {
        mapIndex = 0;
      }
      result += map[mapIndex].encode.get(str[i]);
    }
    return result;
  },
  decodePageNumber: function (str) {
    var result = 0;
    if (typeof str !== 'string') {
      str = '';
    }
    var number = '';
    var i;
    for (i = 0; i < str.length; i++) {
      var mapIndex = i;
      if (i >= map.length) {
        mapIndex = 0;
      }
      number += map[mapIndex].decode.get(str[i]);
    }
    number = parseInt(number);
    if (!isNaN(number) && number > 0) {
      result = number;
    }
    return number;
  }
};

module.exports = helpers;
