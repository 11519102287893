'use strict';

var store = {
  setWhere: function(where){
    document.cookie = "where=" + where + ";path=/";
    return document.cookie;
  },
  getWhere: function(){
    var where = document.cookie.match(/where=([0-9]+);{0,1}/);
    var val = '';
    if(where) {
      val = where[1];
    }
    return val;
  }
};

module.exports = store;
