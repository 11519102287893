'use strict';

var $ = require('../../../bower_components/jquery/dist/jquery');
var Handlebars = require('../../../bower_components/handlebars/handlebars');
var handlebarsHelpers = require('../../../handlebars-helpers');

var filters = {
  default: {
    minReviews: 0,
    minReviewRating: 0
  },
  clear: function() {
    filters.savedCategories = {};
    filters.savedBusinessGroups = {};
    filters.minReviews = filters.default.minReviews;
    filters.minReviewRating = filters.default.minReviewRating;
    filters.ruleContexts = 'categories-disabled';
  },
  clearQueryRules: function() {
    Object.keys(filters.savedCategories)
    .forEach(function(key) {
      if(filters.savedCategories[key].queryRule) {
        delete filters.savedCategories[key];
      }
    });
  },
  setInitial: function(obj) {
    filters.minReviews = Number(obj.RC) || this.default.minReviews;
    filters.minReviewRating = Number(obj.SA) || this.default.minReviewRating;
    if(obj.CAT) {
      obj.CAT.split(',').forEach(function(cat) {
        filters.savedCategories[cat] = {val: 1, selected: true, name: cat};
      });
    }
    if(obj.BG) {
      obj.BG.split(',').forEach(function(bg) {
        filters.savedBusinessGroups[bg] = {val: 1, selected: true, name: bg};
      });
    }
  },
  sortCheckboxFilters: function(hash) {
    var arr = [];
    for(var key in hash) {
      arr.push(hash[key]);
    }
    arr.sort(function(a, b) {
      return b.val - a.val;
    });
    return arr;
  },
  flushOverviewCategories: function() {
    $('.filters-selected-overview-wrap .filter-overview-saved-category').remove();
  },
  flushOverviewGroups: function() {
    $('.filters-selected-overview-wrap .filter-overview-saved-group').remove();
  },
  render: {
    all: function() {
      $('#filter-categories').empty();
      $('#selected-categories-container').empty();
      var key;
      var sortedCategories = filters.sortCheckboxFilters(filters.categories);
      sortedCategories.forEach(function(cat) {
        if(cat.visible) {
          filters.render.categories.normal(cat.name,cat.val);
        }
      });
      var sortedSavedCategories = filters.sortCheckboxFilters(filters.savedCategories);
      sortedSavedCategories.forEach(function(cat) {
        filters.render.categories.saved(cat.name, cat.val);
      });
      filters.showAllButton.categories();

      $('#filter-business-groups').empty();
      $('#selected-business-group-container').empty();
      var key;
      var sortedBusinessGroups = filters.sortCheckboxFilters(filters.businessGroups);

      sortedBusinessGroups.forEach(function(group) {
        if(group.visible) {
          filters.render.businessGroups.normal(group.name,group.val);
        }
      });
      var sortedSavedBusinessGroups = filters.sortCheckboxFilters(filters.savedBusinessGroups);
      sortedSavedBusinessGroups.forEach(function(group) {
        filters.render.businessGroups.saved(group.name, group.val);
      });
      filters.showAllButton.businessGroups();

      filters.render.overview();
    },
    categories: {
      normal: function(name, val){
        if(!filters.categoriesTemplate) return;
        var div = filters.categoriesTemplate({name: name, total: val});
        div = $(div).appendTo('#filter-categories');
        div.data('filterName', name);
        div.data('filterValue', val);
        if(filters.savedCategories[name]) {
          div.css('display', 'none');
        }else {
          div.css('display', 'block');
        }
        return div;
      },
      saved: function(name, val) {
        if(!filters.savedCategoriesTemplate) return;
        var div = filters.savedCategoriesTemplate({name: name, total: val});
        div = $(div).appendTo('#selected-categories-container');
        div.data('filterName', name);
        div.data('filterValue', val);
        return div;
      }
    },
    businessGroups: {
      normal: function(name, val){
        if(!filters.categoriesTemplate) return;
        var div = filters.categoriesTemplate({name: name, total: val});
        div = $(div).appendTo('#filter-business-groups');
        div.data('filterName', name);
        div.data('filterValue', val);
        if(filters.savedBusinessGroups[name]) {
          div.css('display', 'none');
        }else {
          div.css('display', 'block');
        }
        return div;
      },
      saved: function(name, val) {
        if(!filters.savedCategoriesTemplate) return;
        var div = filters.savedCategoriesTemplate({name: name, total: val});
        div = $(div).appendTo('#selected-business-group-container');
        div.data('filterName', name);
        div.data('filterValue', val);
        return div;
      }
    },
    overview: function() {
      if(!filters.savedCategoriesTemplate) return;
      // business groups
      $('.filters-selected-overview-wrap').empty();
      var ckeys = Object.keys(filters.savedCategories);
      ckeys.forEach(function(key) {
        filters.render.overviewFilter(
          filters.savedCategories[key],
          'filter-overview-saved-category'
        );
      })
      var bkeys = Object.keys(filters.savedBusinessGroups);
      bkeys.forEach(function(key) {
        filters.render.overviewFilter(
          filters.savedBusinessGroups[key],
          'filter-overview-saved-group'
        );
      })

      if(!ckeys.length && !bkeys.length) return;
      var resetAllButtonHTML = '<div class="filter-overview-reset"><i class="icon-close-btn"></i>Reset All Filters</div>';
      $('.filters-selected-overview-wrap').append(resetAllButtonHTML);
    },
    overviewFilter: function(o, className) {
      var name = o.name, val = o.val;
      var div = $(filters.savedCategoriesTemplate({name: name, total: val}));
      div.data('filterName', name);
      div.data('filterValue', val);
      div.addClass(className);
      $('.filters-selected-overview-wrap').append(div);
    }
  },
  showMax: 10,
  categories: {},
  categoriesTemplate: null,
  savedCategories: {},
  savedCategoriesTemplate: null,
  businessGroups: {},
  savedBusinessGroups: {},
  ruleContexts: 'categories-enabled',
  getTemplates: function(facets, callback) {
    filters.getCategoriesTemplate(function(){
      filters.getSavedCategoriesTemplate(function(){
        filters.addCategories(facets.categories);
        filters.addBusinessGroups(facets.businessGroups);
        if(typeof callback === 'function') return callback();
        if(callback instanceof Array) {
          callback.forEach(function(func) {
            func();
          });
        }
      });
    });
  },
  getSavedCategoriesTemplate: function() {
    var callbacks = arguments || [];
    $.get('/searchapp-static/templates/filter-selected.hbs').done(function(res) {
      filters.savedCategoriesTemplate = Handlebars.compile(res);
      for(var i = 0, l = callbacks.length; i < l; i += 1){
        if(typeof callbacks[i] === 'function') callbacks[i]();
      }
    });
  },
  getCategoriesTemplate: function() {
    var callbacks = arguments || [];
    $.get('/searchapp-static/templates/filter-checkbox.hbs').done(function(res) {
      filters.categoriesTemplate = Handlebars.compile(res);
      for(var i = 0, l = callbacks.length; i < l; i += 1){
        if(typeof callbacks[i] === 'function') callbacks[i]();
      }
    });
  },

  addBusinessGroups: function(businessGroups) {
    this.businessGroups = {};
    $('#filter-business-groups').empty();
    if(!businessGroups) {
      for(var groupName in filters.savedBusinessGroups) {
        filters.savedBusinessGroups[groupName].val = 0;
      }
    } else {
      // add facet.categories from algolia to filters.categories
      for(var key in businessGroups) {
        filters.businessGroups[key] = {
          name: key,
          val: businessGroups[key],
          visible: true
        };
        // set visible = false if
        // filters.categories[key].name is in filters.savedBusinessGroups
        if(filters.savedBusinessGroups[name]) {
          filters.businessGroups[key].visible = false;
          // update the values of all savedBusinessGroups
          filters.savedBusinessGroups[key].val = businessGroups[key];
        }
      }
    }
    filters.showBusinessGroups();
  },
  showBusinessGroups: function() {
    $('#filter-business-groups').empty();
    $('#selected-business-group-container').empty();
    var key;
    var sortedBusinessGroups = filters.sortCheckboxFilters(filters.businessGroups);

    sortedBusinessGroups.forEach(function(group) {
      if(group.visible) {
        filters.render.businessGroups.normal(group.name,group.val);
      }
    });
    var sortedSavedBusinessGroups = filters.sortCheckboxFilters(filters.savedBusinessGroups);
    sortedSavedBusinessGroups.forEach(function(group) {
      filters.render.businessGroups.saved(group.name, group.val);
    });
    filters.showAllButton.businessGroups();
    if(!Object.keys(filters.businessGroups).length && !Object.keys(filters.savedBusinessGroups).length) {
      $('#filter-label-business-group').hide();
    }else {
      $('#filter-label-business-group').show();
    }
  },
  appliedRules: {
    '1515174258618': 'category-exact-match'
  },

  addCategories: function(categories, what, appliedRules) {
    this.categories = {};
    $('#filter-categories').empty();
    if(!categories) {
      for(var catName in filters.savedCategories) {
        filters.savedCategories[catName].val = 0;
      }
    } else {
      // queryRules are applied at search time
      // in algolia and so have to be applied to the front end
      // retroactively
      // so, delete all filters that are queryRules
      // and add filters that where applied by query rules
      // in algolia afterwards
      Object.keys(filters.savedCategories)
      .forEach(function(key) {
        if(filters.savedCategories[key].queryRule) {
          delete filters.savedCategories[key];
        }
      })
      // add facet.categories from algolia to filters.categories
      var applied = Array.isArray(appliedRules) && appliedRules.find(r => filters.appliedRules[r.objectID]);
      applied = applied && filters.appliedRules[applied.objectID] == 'category-exact-match';
      for(var key in categories) {

        filters.categories[key] = {
          name: key,
          val: categories[key],
          visible: true
        };
        // set visible = false if
        // filters.categories[key].name is in filters.savedCategories
        if(filters.savedCategories[key]) {
          filters.categories[key].visible = false;
          // update the values of all savedCategories
          filters.savedCategories[key].val = categories[key];
        }

        // add query rules
        if(applied && key && what && key.toLowerCase() == what.toLowerCase()) {
          filters.savedCategories[key] = filters.categories[key];
          filters.savedCategories[key].queryRule = true;
        }
      }
    }

    filters.showCategories();
  },
  showCategories: function() {
    $('#filter-categories').empty();
    $('#selected-categories-container').empty();
    var key;
    var sortedCategories = filters.sortCheckboxFilters(filters.categories);
    sortedCategories.forEach(function(cat) {
      if(cat.visible) {
        filters.render.categories.normal(cat.name,cat.val);
      }
    });
    var sortedSavedCategories = filters.sortCheckboxFilters(filters.savedCategories);
    sortedSavedCategories.forEach(function(cat) {
      filters.render.categories.saved(cat.name, cat.val);
    });
    filters.showAllButton.categories();
    if(!Object.keys(filters.categories).length && !Object.keys(filters.savedCategories).length) {
      $('#filter-label-category').hide();
    }else {
      $('#filter-label-category').show();
    }
  },
  showAllButton: {
    categories: function() {
      if(Object.keys(filters.categories).length < filters.showMax) {
        $('#show-all-categories').hide();
        $('#filter-categories').data('show-all', true);
      }else {
        $('#show-all-categories').show().text('+ See more categories');
        $('#filter-categories').data('show-all', false);
        filters.collapseChildren('#filter-categories');
      }
    },
    businessGroups: function() {
      if(Object.keys(filters.businessGroups).length < filters.showMax) {
        $('#show-all-business-groups').hide();
        $('#show-all-business-groups').data('show-all', true);
      }else {
        $('#show-all-business-groups').show().text('See more business groups');
        $('#show-all-business-groups').data('show-all', false);
        filters.collapseChildren('#filter-business-groups');
      }
    }
  },
  expandChildren: function(parentSelector){
    $(parentSelector).children().each(function(index){
      $(this).removeClass('hidden');
    });
  },
  collapseChildren: function(parentSelector){
    $(parentSelector).children().each(function(index){
      if(index >= filters.showMax) $(this).addClass('hidden');
    });
  },
  minReviews: 0,
  minReviewRating: 1,
  selectReviewRating: function(reviewRating) {
    filters.minReviewRating = reviewRating;
  },
  make: {
    numeric: function() {
      var reviewCount = "aggregateRating.ReviewCount>=" + filters.minReviews;
      var reviewRating = "aggregateRating.StarAverage>=" + filters.minReviewRating;
      var paying = '';
      if(filters.paying) {
        paying = ",n49Advertiser=1";
      }
      return reviewCount + ',' + reviewRating + paying;
    },
    facet: function() {
      var facets = [];
      var categories = [];
      var boundaries = [];
      var businessGroups = [];
      for(var key in filters.savedCategories) {
        console.log('filters.savedCategories[key].queryRule', filters.savedCategories[key].queryRule);
        filters.savedCategories[key].queryRule || categories.push('categories:' + key);
      }
      for(var key3 in filters.savedBusinessGroups) {
        businessGroups.push('businessGroups:' + key3);
      }
      for(var key2 in filters.serviceBoundaries) {
        facets.push('serviceBoundaries:' + key2);
      }
      if(categories.length) facets.push(categories);
      // if(boundaries.length) facets.push(boundaries.toString());
      if(businessGroups.length) facets.push(businessGroups);
      return facets;
    },
    ruleContexts: function() {
      return filters.ruleContexts;
    }
  },
  serviceBoundaries: {},
  serviceBoundariesTable: {
    "1": {location_country:'location_name'},
    "3": {location_province: 'location_name', location_country:'location_country'},
    "6": {location_province: 'location_province', location_country:'location_country'},
    "8": {location_province: 'location_province', location_country:'location_country'}
  },
  setServiceBoundaries: function(where) {
    filters.serviceBoundaries = {};
    var list = filters.serviceBoundariesTable[where.location_type];
    for(var key in list) {
      filters.serviceBoundaries[where[list[key]]] = where[list[key]];
    }
  },
};


module.exports = filters;
